import { Button, Divider } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import actions from "../actions";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { getPatientIdentifierFromQrURL, scanQRCode } from "../utils";
import TimeAgo from 'react-timeago';
import { css } from "@emotion/css";


const CssClass = css({
   '& .MuiDivider-root, .MuiButton-root': {
      margin: '10px auto'
   },
   '& .MuiButton-root': {
      margin: '10px auto'
   },
   '& h1': {
      margin: '10px auto'
   },
   margin: 20
})



export default class PatientMoreSection extends Component {

   state = {
      subscriptionModalOpen: false,
   }

   openSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: true });
   }

   closeSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: false });
   }

   linkQRCode = async () => {

      try {

         const patientUrl = await scanQRCode();
         const qr_code = getPatientIdentifierFromQrURL(patientUrl);
         showLoading();

         await request.put('/api/patient/patients/qr-code', { qr_code });

         this.props.setQRCode(qr_code);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {


      let linkQRCodeSectionContentJSX;
      let btnLinkQRCodeCaption;

      if (this.props.qr_code) {
         linkQRCodeSectionContentJSX = 'You have already linked a health band.';
         btnLinkQRCodeCaption = 'REPLACE';
      } else {
         linkQRCodeSectionContentJSX = 'You havent linked a QR code to your account';
         btnLinkQRCodeCaption = 'LINK'
      }

      let subscriptionSectionContentJSX;
      const subscriptionActive = this.props.subscription_ends > Date.now();
      let subscribeButtonCaption;

      if (subscriptionActive) {

         subscriptionSectionContentJSX = <>
            Your subscription expires <b>
               <TimeAgo date={this.props.subscription_ends} />
            </b>
         </>

         subscribeButtonCaption = 'EXTEND';

      } else {
         subscriptionSectionContentJSX = 'You don\'t have an active subscription';
         subscribeButtonCaption = 'SUBSCRIBE';
      }

      return <div className={CssClass}>
         
         <div>
            <h1>Account password</h1>

            <Button variant="contained" onClick={actions.openUpdatePasswordModal}>
               UPDATE
            </Button>

            <Divider />
         </div>

         <div>
            <h1>Link health band</h1>

            <p className="grey-text">
               {linkQRCodeSectionContentJSX}
            </p>
            
            <Button variant="contained" onClick={this.linkQRCode}>
               {btnLinkQRCodeCaption}
            </Button>

            <Divider />
         </div>

         <div>
            <h1>Subscription</h1>

            <p className="grey-text">
               {subscriptionSectionContentJSX}
            </p>
            
            <Button variant="contained" onClick={this.props.openSubscriptionModal}>
               {subscribeButtonCaption}
            </Button>

            <Divider />

         </div>



      </div>
   }
}