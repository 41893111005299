import { Table, TableBody, TableCell, TableRow } from "@mui/material"


export default function HorizontalTable(props) {

   return <Table>
      <TableBody>
         {
            Object.keys(props.data).map(heading => {
               return <TableRow>
                  <TableCell><b>{heading}</b></TableCell>
                  <TableCell>{props.data[heading]}</TableCell>
               </TableRow>
            })
         }
      </TableBody>
   </Table>
}