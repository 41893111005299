import { IconButton } from "@mui/material";
import Component from "@xavisoft/react-component";
import capitalize from "capitalize";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import ResponsiveTable from "./ResponsiveTable";


function formatDataForResponsiveTable(props) {

   const columns =  [ 'NAME', 'EMAIL', 'STATUS', '' ];

   const rows = props.doctors.map(doctor => {


      const { id, approved } = doctor;
      const { name, surname, email } = doctor.doctor;
      const fullName = capitalize.words(`${name} ${surname}`);

      let approvedJSX;
      let action, actionColor, ActionIcon;

      if (approved) {
         approvedJSX = <CheckCircleOutlineIcon color="primary" />
         actionColor = "error";
         ActionIcon = ClearIcon;
         action = props.delete
      } else {
         approvedJSX = <CancelIcon color="error" />
         actionColor = "primary";
         ActionIcon = DoneIcon;
         action = props.approve;
      }

      const actionJSX = <IconButton color={actionColor} onClick={action} data-id={id}>
         <ActionIcon />
      </IconButton>

      return {
         fullName,
         email,
         approvedJSX,
         actionJSX
      }
   });

   return { columns, rows }
}


export default class DoctorManager extends Component {

   render() {

      const { columns, rows } = formatDataForResponsiveTable(this.props);

      const noDataJSX = <p className="font-30 grey-text">
         No doctors have enlisted you as their patient
      </p>

      return <div>
         <ResponsiveTable columns={columns} rows={rows} noDataMessage={noDataJSX} />
      </div>;
   }
}