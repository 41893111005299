import { css } from "@emotion/css";
import { connect } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import actions from "../actions";
import Component from "@xavisoft/react-component";
import { errorToast } from "../toast";
import request from "../request";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";


const divFormClass = css({
   '& > *': {
      margin: '10px auto !important',
   },
   maxWidth: 300,
});


class UpdatePassword extends Component {

   submit = async () => {
      // extract
      const txtOldPassword = document.getElementById('txt-old-password');
      const txtNewPassword = document.getElementById('txt-new-password');
      const txtConfirm = document.getElementById('txt-confirm');

      const old_password = txtOldPassword.value;
      const new_password = txtNewPassword.value;
      const confirm = txtConfirm.value;

      // presence check
      if (!old_password) {
         errorToast("Provide your current password");
         return txtOldPassword.focus();
      }

      if (!new_password) {
         errorToast("Provide your new password");
         return txtNewPassword.focus();
      }

      if (!confirm) {
         errorToast("Reenter your new password");
         return txtConfirm.focus();
      }

      if (new_password !== confirm) {
         errorToast("New password mismatch");
         txtNewPassword.value = '';
         txtConfirm.value = '';
         return txtNewPassword.focus();
      }

      // submit 
      try {

         showLoading();

         const data = { old_password, new_password }; console.log(data);
         await request.post('/api/general/password/update', data);

         swal('Your password has been updated successifully. Use your new password the next time you log in.');

         actions.closeUpdatePasswordModal();

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }


   close = () => {
      actions.closeUpdatePasswordModal();
   }

   render() {
      return <Dialog open={this.props.open}>

         <DialogContent>

            <h2>Update password</h2>

            <div className={divFormClass}>
               <TextField
                  id="txt-old-password"
                  type="password"
                  variant="standard"
                  fullWidth
                  size="small"
                  label="Current password"
               />

               <TextField
                  id="txt-new-password"
                  type="password"
                  variant="standard"
                  fullWidth
                  size="small"
                  label="New password"
               />

               <TextField
                  id="txt-confirm"
                  type="password"
                  variant="standard"
                  fullWidth
                  size="small"
                  label="Confirm new password"
               />
            </div>

         </DialogContent>

         <DialogActions>
            <Button onClick={actions.closeUpdatePasswordModal}>
               CLOSE
            </Button>

            <Button variant="contained" onClick={this.submit}>
               SUBMIT
            </Button>

         </DialogActions>
      </Dialog>
   }

}

function mapStateToProps(state) {
   const { updatePasswordModalOpen } = state;
   return { open: updatePasswordModalOpen };
}

export default connect(mapStateToProps)(UpdatePassword);