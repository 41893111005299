import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { connect } from "react-redux";
import swal from "sweetalert";
import actions from "../actions";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { delay } from "../utils";


class Subscribe extends Component {

   state = {
      values: {
         period: 30
      }
   }

   calculateAmountForSelectedPeriod = () => {
      return parseFloat((this.state.values.period * this.props.subscriptionFee / 30).toFixed(2))
   }

   fetchSubscriptionFee = async (del=1000) => {
      
      try {
         await actions.fetchSubscriptionFee();
      } catch (err) {
         await delay(del)
         del = del * 1.5;
         this.fetchSubscriptionFee(del);
      }
   }

   initiatePayment = async () => {

      // extract
      const amount = this.calculateAmountForSelectedPeriod();

      // submit
      try {
         showLoading();

         let redirect_to;

         if (window.cordova) {
            redirect_to = `kureteq://${window.location.pathname}`
         } else {
            redirect_to = window.location.href;
         }


         const data = { amount, redirect_to };

         const res = await request.post('/api/patient/payments', data);
         const { payment_link } = await res.data;
         
         if (window.cordova) {
            window.cordova.plugins.browser.open(payment_link);
         } else {
            window.location.href = payment_link;
         }

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   componentDidMount() {
      if (!this.props.subscriptionFeeFetched) {
         this.fetchSubscriptionFee();
      }
   }

   render() {

      let primaryActionJSX, contentJSX;

      if (this.props.subscriptionFeeFetched) {

         primaryActionJSX = <Button variant="contained" onClick={this.initiatePayment}>
            INITIATE PAYMENT
         </Button>;

         const amount = this.calculateAmountForSelectedPeriod().toFixed(2);

         contentJSX = <>
            <TextField
               id="txt-period"
               select
               value={this.state.values.period}
               onChange={this.onChangeHandlerGenerator('period')}
               label={this.props.dropDownLabel}
               variant="standard"
               fullWidth
               size="small"
               defaultValue={30}
            >
               <MenuItem value={30}>30 days</MenuItem>
               <MenuItem value={60}>60 days</MenuItem>
               <MenuItem value={90}>90 days</MenuItem>
               <MenuItem value={365}>365 days</MenuItem>
            </TextField>

            <div 
               style={{
                  fontSize: 24,
                  color: 'gray',
                  fontWeight: 'bold',
                  margin: '10px auto',
                  textAlign: 'right'
               }}
            >
               ZWL{amount}
            </div>
         </>
      } else {
         contentJSX = <div className="vh-align" style={{ width: 300, aspectRatio: 1 }}>
            <CircularProgress  />
         </div>
      }

      return <Dialog open={this.props.open}>
         <DialogTitle>
            <h2>{this.props.title}</h2>
         </DialogTitle>
         
         <DialogContent>
            {contentJSX}
         </DialogContent>

         <DialogActions>
            {primaryActionJSX}
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}

function mapStateToProps(state) {
   const { subscriptionFee } = state;
   return { subscriptionFee, subscriptionFeeFetched: subscriptionFee !== null };
}

export default connect(mapStateToProps)(Subscribe);