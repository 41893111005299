
import { ACTIONS } from "./constants";
import request from "./request";
import store from "./store";

function setDeviceInfo(device) {

   const action = {
      type: ACTIONS.SET_DEVICE_INFO,
      payload:  device
   }

   store.dispatch(action);

}

function setCurrentRoute(route) {

   const action = {
      type: ACTIONS.SET_CURRENT_ROUTE,
      payload:  { route }
   }

   store.dispatch(action);

}

function closeUpdatePasswordModal() {
   const action = {
      type: ACTIONS.CLOSE_UPDATE_PASSWORD_MODAL,
   }

   store.dispatch(action);
}

function openUpdatePasswordModal() {
   const action = {
      type: ACTIONS.OPEN_UPDATE_PASSWORD_MODAL,
   }

   store.dispatch(action);
}

async function fetchSubscriptionFee() {
   const { data } = await request.get('/api/general/subscription-fee');
   const { subscription_fee:subscriptionFee } = data;

   setSubscriptionFee(subscriptionFee);
}

async function setSubscriptionFee(subscriptionFee) {
   store.dispatch({
      type: ACTIONS.SET_SUBSCRIPTION_FEE,
      payload: { subscriptionFee }
   });
}

function setAuthenticated(authenticated=true, dashboardRoute) {
   const action = {
      type: ACTIONS.SET_AUTHENTICATED,
      payload: { authenticated, dashboardRoute },
   }

   store.dispatch(action);
}


const actions = {
   closeUpdatePasswordModal,
   fetchSubscriptionFee,
   openUpdatePasswordModal,
   setAuthenticated,
   setCurrentRoute,
   setDeviceInfo,
   setSubscriptionFee,
}


export default actions;