import { MetaTags } from "react-meta-tags";
import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import Page from "./Page";


class Home extends Page {

   _render() {

      const aboutUsOrNotJSX = window.cordova ? undefined : <AboutUs />
      return <>

         <MetaTags>
            <title>Home | KureTeq</title>
         </MetaTags>

         <Hero />
         {aboutUsOrNotJSX}
         
      </>
   }
}

export default Home;