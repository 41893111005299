
import { Button } from "@mui/material"

function RoundedButton(props) {


   const passedStyle = props.style || {};
   let height = passedStyle.height || '40px';

   if (typeof height === 'number')
      height = height + 'px';

   const style = {
      ...passedStyle,
      borderRadius: 'calc(var(--height) / 2)',
      height: 'var(--height)',
      '--height': height,
   };

   props = { ...props, style: undefined }

   return <Button
      {...props}
      style={style}
   >
      {props.children}
   </Button>
}

export default RoundedButton;