import { Button } from "@mui/material";
import Page from "./Page";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import ScanIcon from '@mui/icons-material/QrCodeScanner';
import { getPatientIdentifierFromQrURL, logout, scanQRCode } from '../utils';
import swal from "sweetalert";
import ScannedPatient from "../components/ScannedPatientView";
import request from "../request";
import { connect } from 'react-redux';
import { MetaTags } from "react-meta-tags";
import LooksOne from "@mui/icons-material/LooksOne";
import LooksTwo from "@mui/icons-material/LooksTwo";
import SideMenu from "../components/SideMenu";
import actions from "../actions";
import { hideLoading, showLoading } from "../loading";



class EmergencyResponderDashboard extends Page {

   state = {
      patient: null,
      tab: 'scan',
   }

   closePatientView = () => {
      return this.updateState({ patient: null });
   }

   scanPatientBand = async () => {
      try {
         const patientUrl = await scanQRCode();
         const patientId = getPatientIdentifierFromQrURL(patientUrl)
         this.fetchPatient(patientId);
      } catch (err) {
         swal(String(err));
      }
   }

   fetchPatient = async (patientId) => {
      try {

         showLoading();
         const res = await request.get(`/api/responder/patients/${patientId}`);
         const patient = res.data;
         this.updateState({ patient });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   onTabChange = (tab) => {
      if (tab === 'update-password')
         return actions.openUpdatePasswordModal();
   }

   componentDidMount() {
      
      super.componentDidMount();

      let route;

      if (window.cordova)
         route = window.location.hash;
      else
         route = window.location.pathname;
      
      if (route.indexOf('/patients/') > 0) {
         const patientId = getPatientIdentifierFromQrURL(route);
         this.fetchPatient(patientId);
      }
   }

   _render() {


      let backJSX;
      const isScreenSizeSmall = [ 'xs', 'sm' ].includes(this.props.device.size);

      const tabs = [
         {
            icon: LooksOne,
            caption: 'Respond to emergency',
            value: 'scan'
         }
      ];

      if (this.props.authenticated) {
         tabs.push({
            icon: LooksTwo,
            caption: 'Update Password',
            value: 'update-password'
         });
      }

      if (isScreenSizeSmall) {

         let backAction, backCaption;
         if (this.props.authenticated) {
            backAction = logout;
            backCaption = 'LOGOUT';
         } else {
            backAction = () => window.history.back();
            backCaption = 'BACK'
         }

         backJSX = <div
            style={{
               position: 'absolute',
               bottom: 30,
               right: 30
            }}
         >
            <Button startIcon={<BackIcon />} onClick={backAction}>
               {backCaption}
            </Button>
         </div>

      }

      let titleJSX;
      const textTitleJSX = <h4 style={{ marginLeft: 10 }}>Scan health band</h4>

      if (isScreenSizeSmall) {
         titleJSX = <>
            <SideMenu 
               items={tabs} 
               value={this.state.tab} 
               onChange={this.onTabChange} 
               variant="temporary"
               onScanPatientClicked={this.scanPatientBand}
            />
            {textTitleJSX}
         </>
      } else {
         titleJSX = textTitleJSX;
      }

      let contentJSX = <div>
         <div 
            style={{ 
               height: 'var(--window-height)',
               display: 'grid',
               gridTemplateRows: '60px auto',
            }}
         >
            <div
               className="v-align"
               style={{
                  borderBottom: '1px solid #ccc',
               }}
            >
               {titleJSX}
            </div>
            <div 
               style={{ 
                  position: 'relative',
               }} 
               className="vh-align"
            >

               
               <div>
                  <Button 
                     startIcon={<ScanIcon />} 
                     variant="contained" 
                     onClick={this.scanPatientBand}
                     size="large"
                     className="pulse"
                  >
                     SCAN 
                  </Button>
               </div>
               
               {backJSX}
            </div>
         </div>

         <ScannedPatient 
            data={this.state.patient}
            close={this.closePatientView}
         />
      </div>

      if (!isScreenSizeSmall) {
         contentJSX = <div
            style={{
               display: 'grid',
               gridTemplateColumns: '300px auto',
            }}
         >
            <div>
               <SideMenu 
                  items={tabs} 
                  value={this.state.tab} 
                  onChange={this.onTabChange}
                  onScanPatientClicked={this.scanPatientBand}
               />
            </div>
            
            {contentJSX}
         </div>
      }
         

      return <>

         <MetaTags>
            <title>Emergency Responder Dashboard | KureTeq</title>
         </MetaTags>

         {contentJSX}

      </>
   }
}

function mapStateToProps(state) {
   return { 
      authenticated: state.authenticated,
      device: state.device,
   };
}

export default connect(mapStateToProps)(EmergencyResponderDashboard);