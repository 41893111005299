
import Add from "@mui/icons-material/Add";
import Looks3 from "@mui/icons-material/Looks3";
import Looks4 from "@mui/icons-material/Looks4";
import Looks5 from "@mui/icons-material/Looks5";
import LooksOne from "@mui/icons-material/LooksOne";
import LooksTwo from "@mui/icons-material/LooksTwo";
import Refresh from "@mui/icons-material/Refresh";
import { Button, Fab, IconButton } from "@mui/material";
import { connect } from "react-redux";
import swal from "sweetalert";
import ContactEditor from "../components/ContactEditor";
import ContactInfoManager from "../components/ContactInfoManager";
import DoctorManager from "../components/DoctorManager";
import PatientDashboardMedicalConditionManager from "../components/PatientDashboardMedicalConditionManager";
import PatientDashboardPrescriptions from "../components/PatientDashboardPrescriptions";
import PatientMoreTab from "../components/PatientMoreSection";
import SideMenu from "../components/SideMenu";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { delay, requestConfirmation } from "../utils";
import Page from "./Page";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Subscribe from "../components/Subscribe";
import { MetaTags } from "react-meta-tags";


const menuItems = [
   {
      icon: LooksOne,
      caption: 'Emergency contacts',
      value: 'contact-info'
   },
   {
      icon: LooksTwo,
      caption: 'Medical Conditions',
      value: 'medical-conditions'
   },
   {
      icon: Looks3,
      caption: 'Doctors',
      value: 'doctors',
   },
   {
      icon: Looks4,
      caption: 'Prescriptions',
      value: 'prescriptions',
   },
   {
      icon: Looks5,
      caption: 'More...',
      value: 'more',
   },
]


class PatientDashboard extends Page {

   state = {
      sideMenuValue: 'contact-info',
      data: null,
      dataFetched: false,
      contactEditorMode: null,
      contactBeingEdited: null,
      subscriptionModalOpen: false,
      divTitleHeight: '60px',
   }

   openSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: true });
   }

   closeSubscriptionModal = () => {
      return this.updateState({ subscriptionModalOpen: false });
   }

   setQRCode = (qr_code) => {
      const data = { ...this.state.data, qr_code };
      return this.updateState({ data });
   }

   addContact = () => {
      return this.updateState({
         contactEditorMode: 'add',
      });
   }

   closeContactEditor = (data) => {

      const updates = { contactEditorMode: null };

      if (data) {

         if (this.state.contactEditorMode === 'add') {
            const contact_info = [ ...this.state.data.contact_info, data ];
            updates.data = { ...this.state.data, contact_info };
         } else {

            const { id } = this.state.contactBeingEdited;
            const contact_info = this.state.data.contact_info.map(contact => {

               if (contact.id === id) {
                  return { ...contact, ...data };
               }

               return contact;
               
            });
            
            updates.data = { ...this.state.data, contact_info };
         }
      }


      return this.updateState(updates);
   }

   deleteContact = async (e) => {

      const id = parseInt(e.currentTarget.getAttribute('data-id'));
      const [ contact ] = this.state.data.contact_info.filter(item => item.id === id);

      // confirm
      const question = `Do you really want to delete "${contact.data}"?`;
      const confirm = await requestConfirmation({ question, cancelButtonCaption: 'Cancel', confirmButtonCaption: 'Yes, delete' });

      if (!confirm)
         return;
      
      // delete
      try {

         showLoading();

         await request.delete(`/api/patient/contact-info/${id}`);

         const contact_info = this.state.data.contact_info.filter(item => item.id !== id);
         const data = { ...this.state.data, contact_info }

         await this.updateState({ data });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   editContact = async (e) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id'));
      const [ contactBeingEdited ] = this.state.data.contact_info.filter(item => item.id === id);

      await this.updateState({
         contactBeingEdited,
         contactEditorMode: 'edit'
      })

   }

   onMedicalConditionAccessibleToChange = async (id, accessible_to) => {

      const medical_conditions = this.state.data.medical_conditions.map(condition => {
         if (id === condition.id) {
            condition = { ...condition, accessible_to }
         } 

         return condition;
      });

      try {   

         showLoading();

         await request.put(`/api/patient/medical-conditions/${id}/priviledge`, { accessible_to });
         const data = { ...this.state.data, medical_conditions };
         
         return this.updateState({ data })

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
      
   }

   deleteDoctorEnlistment = async (e) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id'));

      try {
         showLoading();

         await request.delete(`/api/patient/doctor-enlistments/${id}`);

         const doctor_enlistments = this.state.data.doctor_enlistments.filter(item => item.id !== id);
         const data = { ...this.state.data, doctor_enlistments };

         await this.updateState({ data });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   approveDoctorEnlistment = async (e) => {
      const id = parseInt(e.currentTarget.getAttribute('data-id'));

      try {
         showLoading();

         await request.post(`/api/patient/doctor-enlistments/${id}/approval`);

         const doctor_enlistments = this.state.data.doctor_enlistments.map(enlistment => {

            if (enlistment.id !== id)
               return enlistment;

            return { ...enlistment, approved: true }
         });

         const data = { ...this.state.data, doctor_enlistments };

         await this.updateState({ data });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   onSideMenuChange = (sideMenuValue) => {
      return this.updateState({ sideMenuValue });
   }

   fetchData = async () => {
      
      try {

         showLoading();

         const url = `/api/patient/my-info`;
         const res = await request.get(url);
         const data = res.data;

         await this.updateState({
            data,
            dataFetched: true,
         });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   calculateTitleHeight = async () => {
      console.log('here')
      await delay(50);
      const divTitleHeight = document.getElementById('div-title').scrollHeight + 'px';
      await this.updateState({ divTitleHeight });
   }

   componentDidMount() {
      super.componentDidMount();
      this.fetchData();

      const divTitleSizeObserver = new window.ResizeObserver(this.calculateTitleHeight);
      divTitleSizeObserver.observe(document.getElementById('div-title'));
      this.calculateTitleHeight();


      // to be removed
      window.test = () => {
         return this.updateState({ data: { ...this.state.data, subscription_ends: Date.now() + 24 *3600000 }})
      }

   }

   _render() {


      let contentJSX;
      let actionsJSX;
      let titleJSX;

      if (this.state.dataFetched) {
         switch (this.state.sideMenuValue) {
            case 'contact-info':
   
               contentJSX = <>
                  <ContactInfoManager
                     contacts={this.state.data.contact_info}
                     add={this.addContact}
                     edit={this.editContact}
                     delete={this.deleteContact}
                  />

                  <ContactEditor
                     mode={this.state.contactEditorMode}
                     data={this.state.contactBeingEdited}
                     close={this.closeContactEditor}
                  />
               </>

               actionsJSX = <>
                  <Fab
                     onClick={this.addContact}
                     color="primary"
                     size="small"
                     style={{
                        marginRight: 20
                     }}
                  >
                     <Add />
                  </Fab>
               </>

               titleJSX = <Title>Emergency contacts</Title>
               break;

            case 'medical-conditions':

               contentJSX = <PatientDashboardMedicalConditionManager
                  medical_conditions={this.state.data.medical_conditions}
                  onAccessibleToChange={this.onMedicalConditionAccessibleToChange}
               />


               titleJSX = <Title>Medical Conditions</Title>

               break;

            case 'doctors':

               contentJSX = <DoctorManager
                  doctors={this.state.data.doctor_enlistments}
                  approve={this.approveDoctorEnlistment}
                  delete={this.deleteDoctorEnlistment}
               />

               titleJSX = <Title>Doctors</Title>

               break;
            
            case 'prescriptions':

               contentJSX = <PatientDashboardPrescriptions
                  prescriptions={this.state.data.prescriptions}
               />

               titleJSX = <Title>Prescriptions</Title>

               break;

            case 'more':

               contentJSX = <PatientMoreTab 
                  setQRCode={this.setQRCode} 
                  qr_code={this.state.data ? this.state.data.qr_code : undefined}
                  openSubscriptionModal={this.openSubscriptionModal}
               />


               titleJSX = <Title>Account Settings</Title>

               break;
               
            default:
               contentJSX = <>Something went wrong</>
         }
      } else {
         contentJSX = <div className="vh-align fill-container">
            <div>
               <p className="grey-text font-30">
                  We failed to retrieve your data. Please try again.
               </p>

               <Button onClick={this.fetchData}>
                  RETRY
               </Button>
            </div>
         </div>
      }

      const isSmallScreen = this.props.device.size === 'sm' || this.props.device.size === 'xs';

      if (isSmallScreen) {
         titleJSX = <>
            <SideMenu
               onChange={this.onSideMenuChange}
               value={this.state.sideMenuValue}
               items={menuItems}
               variant="temporary"
            />
            {titleJSX}
         </>

      }

      const notSubscribed = this.state.dataFetched && this.state.data.subscription_ends < Date.now();
      let subscriptionModalTitle, subscriptionModalDropdownCaption

      if (notSubscribed) {
         titleJSX = <div style={{ width: '100%' }}>
            <div style={{
               color: 'red',
               padding: 15,
            }}>
               
               <span>You don't have an active subscription. Subscribe to avoid disruption of service</span>
               <IconButton onClick={this.openSubscriptionModal}>
                  <OpenInNewIcon />
               </IconButton>
                  
            </div>
            
            <div className="v-align">{titleJSX}</div>
            
         </div>

         subscriptionModalDropdownCaption = 'Subscribe for';
         subscriptionModalTitle = 'Subscribe';

      } else {
         subscriptionModalDropdownCaption = 'Extend by';
         subscriptionModalTitle = 'Extend subscription';
      }


      contentJSX = <>
         <MetaTags>
            <title>Patient Dashboard | KureTeq</title>
         </MetaTags>

         <div 
            style={{
               height: 'var(--window-height)',
               display: 'grid',
               gridTemplateRows: `${this.state.divTitleHeight} auto 60px`
            }}
         >

            <div
               className="valign"
               style={{
                  borderBottom: '1px solid #ccc'
               }}
            >
               <div id="div-title">
                  {titleJSX}
               </div>
            </div>

            <div style={{ overflowY: 'auto' }}>{contentJSX}</div>

            <div
               style={{
                  borderTop: '1px solid #ccc'
               }} 
            >
               <div className="valign fill-container" style={{ justifyContent: 'right' }}>
                  <Fab
                     onClick={this.fetchData}
                     size="small"
                     style={{
                        marginRight: 10,
                        color: 'var(--primary)',
                        backgroundColor: 'white',
                     }}
                  >
                     <Refresh />
                  </Fab>

                  {actionsJSX}

               </div>

               <Subscribe
                  dropDownLabel={subscriptionModalDropdownCaption}
                  title={subscriptionModalTitle}
                  open={this.state.subscriptionModalOpen}
                  close={this.closeSubscriptionModal}
               />
            </div>
         </div>
      </>

      if (isSmallScreen) {
         return contentJSX;
      }

      return <div
         style={{
            display: 'grid',
            gridTemplateColumns: '300px auto',
            height: 'var(--window-height)'
         }}
      >
         <div>
            <SideMenu
               onChange={this.onSideMenuChange}
               value={this.state.sideMenuValue}
               items={menuItems}
            />
         </div>

         {contentJSX}
      
      </div>
   }
}

function Title(props) {
   return <h3 style={{ marginLeft: 15, display: 'inline-block' }}>
      {props.children}
   </h3>
}

function mapStateToProps(state) {
   const { device } = state;
   return { device };
}

export default connect(mapStateToProps)(PatientDashboard);