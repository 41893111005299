import { css } from "@emotion/css";
import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { v4 } from "uuid";
import actions from "../actions";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import { delay } from "../utils";

const divMoreWrapperClass = css({
   '& .MuiDivider-root': {
      margin: '20px auto'
   }
});

class AdminMoreSection extends Component {

   updateSubscriptionFee = async () => {

      const txtSubscriptionFee = document.getElementById('txt-subscription-fee');
      const subscription_fee = parseFloat(txtSubscriptionFee.value);

      if (subscription_fee <= 0) {
         errorToast('Provide a valid subscription fee');
         return txtSubscriptionFee.focus();
      }
      

      try {

         showLoading();
         await request.post('/api/admin/subscription-fee', { subscription_fee });
         actions.setSubscriptionFee(subscription_fee);
         swal('Sucessfully updated the subscription fee');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   generateQRCodes = () => {

      // validate
      const txtCount = document.getElementById('txt-qrcode-count')
      const count = parseInt(txtCount.value) || 0;

      if (!count) {
         errorToast('Provide a valid count');
         return txtCount.focus();
      }

      // generate QR codes
      const { protocol, host } = window.location;
      const baseUrl = `${protocol}//${host}`;
      const qr_codes = [];

      for (let i = 0; i < count; i++) {
         const uuid = v4();
         qr_codes.push(`${baseUrl}/er-dashboard/patients/${uuid}`);
      }

      // save to file
      var blob = new Blob([ qr_codes.join('\r\n') ], { type: "text/plain;charset=utf-8" });
      FileSaver.saveAs(blob, "KureTeq New QR Codes.txt");

   }

   fetchSubscriptionFee = async (del=5000) => {
      try {
         await actions.fetchSubscriptionFee();
         this.displaySubscriptionFee();
      } catch (err) {
         await delay(del);
         del = del * 1.5;
         this.fetchSubscriptionFee();
      }
   }

   async displaySubscriptionFee() {
      await delay(1000);
      document.getElementById('txt-subscription-fee').value = this.props.subscriptionFee;
   }

   async componentDidMount() {
      if (this.props.subscriptionFeeFetched) {
         await this.displaySubscriptionFee();
      } else {
         await this.fetchSubscriptionFee();
      }
   }

   render() {

      let subscriptionFeeJSX;

      if (this.props.subscriptionFeeFetched) {
         subscriptionFeeJSX = <>
            <TextField
               id="txt-subscription-fee"
               variant="outlined"
               size="small"
               label="Monthly subscription fee"
               type="number"
               InputLabelProps={{
                  shrink: true
               }}
            />

            <Button variant="contained" style={{ marginLeft: 10 }} onClick={this.updateSubscriptionFee}>
               UPDATE
            </Button>
         </>
      } else {
         subscriptionFeeJSX = <div className="vh-align">
            <CircularProgress size="small" />
         </div>
      }

      return <div
         style={{
            overflowY: 'auto',
            height: 'var(--window-height)',
         }}
      >
         <div style={{ padding: 20 }} className={divMoreWrapperClass}>
            <h1>Update password</h1>

            <Button variant="contained" onClick={actions.openUpdatePasswordModal}>
               UPDATE
            </Button>

            <Divider/>

            <h1>Generate QR codes</h1>

            <TextField
               id="txt-qrcode-count"
               variant="outlined"
               size="small"
               label="How many?"
               type="number"
            />

            <Button 
               variant="contained" 
               style={{ marginLeft: 10 }}
               onClick={this.generateQRCodes}
            >
               GENERATE
            </Button>

            <Divider/>

            <h1>Update subscription fee</h1>

            {subscriptionFeeJSX}

            <Divider/>

         </div>
      </div>
   }
}

function mapStateToProps(state) {
   const { subscriptionFee } = state;
   return { subscriptionFee, subscriptionFeeFetched: subscriptionFee !== null };
}

export default connect(mapStateToProps)(AdminMoreSection);