import Component from "@xavisoft/react-component";
import { v4 } from "uuid";
import { toCanvas } from 'qrcode';


export default class QrCode extends Component {

   id = 'div-' + v4();

   state = {
      message: 'Waiting...',
   }


   resize = () => {
      const div = document.getElementById(this.id);
      const canvas = div.querySelector('canvas');
      
      const canvasWidth = canvas.offsetWidth;
      const divWidth = div.offsetWidth;

      const scale = divWidth / canvasWidth;
      const translate = ((scale - 1) * canvasWidth / 2) + 'px';

      const transform = `translate(${translate}, ${translate}) scale(${scale}) `;
      console.log(transform)
      canvas.style.transform = transform;

      // scaling doesn't affect the parent, so we have to do it manually
      div.style.height = scale * canvas.offsetHeight + 'px';

   }

   componentWillUnmount() {
      this.resizeObserver.disconnect();
   }

   componentDidMount() {

      const div = document.getElementById(this.id);

      const resizeObserver = new window.ResizeObserver(this.resize);
      this.resizeObserver = resizeObserver;
      resizeObserver.observe(div);

      const canvas = div.querySelector('canvas');
      toCanvas(canvas, this.props.code, err => {

         let message;
         if (err) {
            console.error(err);
            message = 'Somethin went wrong';
         }

         this.updateState({ message });
         this.resize();

      });
   }

   render() {

      const width = this.props.width || '100%';

      return <div id={this.id}  style={{ width }}>
         <canvas />
         <span>{this.state.message}</span>
      </div>
   }
}