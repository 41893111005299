import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Button, Table, TableBody, TableCell, TableRow, IconButton, Paper } from "@mui/material";
import Component from "@xavisoft/react-component";
import { hideLoading, showLoading } from '../loading';
import swal from 'sweetalert';
import request from '../request';
import Refresh from '@mui/icons-material/Refresh';
import { calculateAge, requestConfirmation } from '../utils';
import { words as capitalize } from 'capitalize';
import MedicalConditionEditor from './MedicalConditionEditor';
import PrescriptionEditor from './PrescriptionEditor';
import { css } from '@emotion/css';



const containerGridClass = css({
   display: 'grid',
   gridTemplateColumns: '1fr 1fr',
   padding: 10,
   columnGap: 20,
   rowGap: 20,

   '& > *': {
      height: 'calc(var(--window-height) / 2) !important',
      overflowY: 'auto',
      padding: 20,
   }
})



function Heading(props) {
   return <div
      style={{
         fontSize: 30,
         color: 'grey',
      }}
   >
      {props.children}
   </div>
}

function MedicalCondition(props) {

   let actionsJSX;

   if (props.added_by_you) {
      actionsJSX = <>
         <IconButton data-id={props.id} onClick={props.edit}>
            <Edit />
         </IconButton>

         <IconButton size='small' color='error' data-id={props.id} onClick={props.delete}>
            <Delete />
         </IconButton>
      </>
   }

   return <div
      style={{
         display: 'grid',
         gridTemplateColumns: 'auto 100px',
         borderBottom: '1px solid #ddd',
         padding: '10px 0',
      }}
   >
      <div className='valign fill-container' style={{ padding: 5, color: 'grey', fontStretch: 'ultra-condensed' }}>
         {props.description}
      </div>

      <div className='valign fill-container' style={{ justifyContent: 'right' }}>
         {actionsJSX}
      </div>
   </div>
}

function Prescription(props) {

   let actionsJSX;

   if (!props.dispensed && props.prescribed_by_you) {
      actionsJSX = <>
         <IconButton color='error' data-id={props.id} onClick={props.delete}>
            <Delete />
         </IconButton>
         <IconButton data-id={props.id} onClick={props.edit}>
            <Edit />
         </IconButton>
      </>
   }


   return <div
      style={{
         display: 'grid',
         gridTemplateColumns: 'auto 100px',
         borderBottom: '1px solid #ddd',
         padding: '10px 0',
      }}
   >
      <div className='valign fill-container' style={{ padding: 5, color: 'grey', fontStretch: 'ultra-condensed', fontSize: 18 }}>
         <div>
            <div>{props.items}</div>
            <div
               style={{
                  color: props.dispensed ? 'var(--primary)' : 'var(--accent-1)',
                  fontSize: 12,
               }}
            >
               {props.dispensed ? 'Dispensed' :  'Not yet dispensed' }
            </div>
         </div>
      </div>

      <div className='valign fill-container' style={{ justifyContent: 'right' }}>
         {actionsJSX}
      </div>
   </div>
}


class Patient extends Component {

   state = {
      data: null,
      dataFetched: false,
      medicalConditionEditorMode: null,
      medicalConditionBeingEdited: null,
      prescriptionEditorMode: null,
      prescriptionBeingEdited: null
   }

   addMedicalCondition = () => {
      return this.updateState({ medicalConditionEditorMode: 'add' });
   }

   closeMedicalConditionEditor = (data) => {

      const updates = { medicalConditionEditorMode: null };

      if (data) {

         data = { ...data, added_by_you: true };

         if (this.state.medicalConditionEditorMode === 'add') {
            const medical_conditions = [ ...this.state.data.medical_conditions, data ];
            updates.data = { ...this.state.data, medical_conditions };
         } else {

            const { id } = this.state.medicalConditionBeingEdited;
            const medical_conditions = this.state.data.medical_conditions.map(condition => {

               if (condition.id === id) {
                  return { ...condition, ...data };
               }

               return condition;
               
            });
            
            updates.data = { ...this.state.data, medical_conditions };
         }
      }

      return this.updateState(updates);
   }

   editMedicalCondition = async (event) => {
      const id = parseInt(event.currentTarget.getAttribute('data-id'));
      const [ medicalConditionBeingEdited ] = this.state.data.medical_conditions.filter(item => item.id === id);

      return this.updateState({
         medicalConditionBeingEdited,
         medicalConditionEditorMode: 'edit',
      });

   }

   deleteMedicalCondition = async (event) => {
      const id = parseInt(event.currentTarget.getAttribute('data-id'));
      const [ medicalCondition ] = this.state.data.medical_conditions.filter(item => item.id === id);

      const question = `Do you really want to delete medical condition "${medicalCondition.description}"?`;
      const confirm = await requestConfirmation({ question, confirmButtonCaption: 'Yes, delete'});

      if (!confirm)
         return;

      try {

         showLoading();
         await request.delete(`/api/doctor/medical-conditions/${id}`);

         const medical_conditions = this.state.data.medical_conditions.filter(item => item.id !== id);
         const data = { ...this.state.data, medical_conditions } 
         await this.updateState({ data });

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }



   addPrescription = () => {
      return this.updateState({ prescriptionEditorMode: 'add' });
   }

   closePrescriptionEditor = (data) => {

      const updates = { prescriptionEditorMode: null };

      if (data) {

         data = { ...data, prescribed_by_you: true };

         if (this.state.prescriptionEditorMode === 'add') {
            const prescriptions = [ ...this.state.data.prescriptions, data ];
            updates.data = { ...this.state.data, prescriptions };
         } else {

            const { id } = this.state.prescriptionBeingEdited;
            const prescriptions = this.state.data.prescriptions.map(prescription => {

               if (prescription.id === id) {
                  return { ...prescription, ...data };
               }

               return prescription;
               
            });
            
            updates.data = { ...this.state.data, prescriptions };
         }
      }


      return this.updateState(updates);
   }

   editPrescription = async (event) => {
      const id = parseInt(event.currentTarget.getAttribute('data-id'));
      const [ prescriptionBeingEdited ] = this.state.data.prescriptions.filter(item => item.id === id);

      return this.updateState({
         prescriptionBeingEdited,
         prescriptionEditorMode: 'edit',
      });

   }

   deletePrescrtiption = async (event) => {
      const id = parseInt(event.currentTarget.getAttribute('data-id'));
      const [ prescription ] = this.state.data.prescriptions.filter(item => item.id === id);

      if (prescription.dispensed)
         return swal('You cannot delete a prescription that is already dispensed');

      const question = `Do you really want to delete the prescription "${prescription.items}"?`;
      const confirm = await requestConfirmation({ question, confirmButtonCaption: 'Yes, delete' });

      if (!confirm)
         return;

      try {

         showLoading();
         await request.delete(`/api/doctor/prescriptions/${id}`);

         const prescriptions = this.state.data.prescriptions.filter(item => item.id !== id);
         const data = { ...this.state.data, prescriptions }

         await this.updateState({ data });

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }


   fetchData = async () => {

      try {
         showLoading();
         const { data } = await request.get(`/api/doctor/patients/${this.props.id}`); console.log(data);
         await this.updateState({ data, dataFetched: true }); 
      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   componentDidMount() {
      this.fetchData();
   }

   render() {

      let contentJSX;

      if (this.state.dataFetched) {

         const { national_id, sex, dob, email, medical_conditions, prescriptions } = this.state.data;
         const { name, surname } = this.state.data;

         const fullName = capitalize(`${name} ${surname}`);

         let medicalConditionsJSX, prescriptionsJSX;
         
         if (medical_conditions.length) {
            medicalConditionsJSX = medical_conditions.map(condition => {
               return <MedicalCondition
                  {...condition}
                  edit={this.editMedicalCondition}
                  delete={this.deleteMedicalCondition}
               />
            })
         } else {
            medicalConditionsJSX = <p style={{ fontSize: 24 }} className="grey-text">
               No medical conditions
            </p>
         }

         if (prescriptions.length) {
            prescriptionsJSX = prescriptions.map(prescription => {
               return <Prescription 
                  {...prescription}
                  edit={this.editPrescription}
                  delete={this.deletePrescrtiption}   
                  email={this.emailPrescription}
               />
            })
         } else {
            prescriptionsJSX = <p style={{ fontSize: 24 }} className="grey-text">
               No prescriptions
            </p>
         }

         contentJSX = <>
            <div
               style={{
                  fontSize: 40,
                  padding: '20px',
                  fontWeight: 'bold',
                  borderBottom: '1px solid #ccc',
                  color: 'grey'
               }}
               className="valign"
            >
               <IconButton style={{ fontSize: 40, marginRight: 10 }} onClick={this.props.close}>
                  <ArrowBack fontSize='40px' color="primary" />
               </IconButton>
               
               {fullName}
            </div>

            <div style={{ padding: 20 }}>
               <div className={containerGridClass}>

                  <Paper elevation={2}>
                     <Heading>Personal Info</Heading>

                     <Table>
                        <TableBody>

                           <TableRow>
                              <TableCell><b>NAME</b></TableCell>
                              <TableCell>
                                 {name}
                              </TableCell>
                           </TableRow>

                           <TableRow>
                              <TableCell><b>NATIONAL_ID</b></TableCell>
                              <TableCell>
                                 {national_id}
                              </TableCell>
                           </TableRow>
                           
                           <TableRow>
                              <TableCell><b>AGE</b></TableCell>
                              <TableCell>
                                 {calculateAge(dob)}
                              </TableCell>
                           </TableRow>
                           
                           <TableRow>
                              <TableCell><b>SEX</b></TableCell>
                              <TableCell>
                                 {capitalize(sex)}
                              </TableCell>
                           </TableRow>
                           
                           <TableRow>
                              <TableCell><b>EMAIL</b></TableCell>
                              <TableCell>
                                 {email}
                              </TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </Paper>

                  <Paper elevation={2}>

                     <div style={{ display: 'grid', gridTemplateColumns: 'auto 100px' }}>
                        <Heading>Medical Conditions</Heading>
                        <div className='valign primary-text' style={{ justifyContent: 'right' }}>
                           <Button onClick={this.addMedicalCondition} variant="contained" size='small' startIcon={<AddIcon />}>
                              ADD
                           </Button>
                        </div>
                     </div>

                     {medicalConditionsJSX}

                     <MedicalConditionEditor
                        open={!!this.state.medicalConditionEditorMode}
                        mode={this.state.medicalConditionEditorMode}
                        close={this.closeMedicalConditionEditor}
                        patientId={this.props.id}
                        data={this.state.medicalConditionBeingEdited}
                     />
                     
                  </Paper>

                  <Paper elevation={2}>

                     <div style={{ display: 'grid', gridTemplateColumns: 'auto 100px' }}>
                        <Heading>Prescriptions</Heading>
                        <div className='valign primary-text' style={{ justifyContent: 'right' }}>
                           <Button onClick={this.addPrescription} variant="contained" size='small' startIcon={<AddIcon />}>
                              ADD
                           </Button>
                        </div>
                     </div>

                     {prescriptionsJSX}

                     <PrescriptionEditor
                        open={!!this.state.prescriptionEditorMode}
                        mode={this.state.prescriptionEditorMode}
                        close={this.closePrescriptionEditor}
                        patientId={this.props.id}
                        data={this.state.prescriptionBeingEdited}
                     />
                     
                  </Paper>
               </div>
            </div>
         </>
      } else {
         return <div className='vh-align fill-container'>
            <div>
               <p>Failed to get data</p>
               <Button onClick={this.fetchData}>
                  RETRY
               </Button>
            </div>
         </div>
      }

      return <div
         style={{
            height: 'var(--window-height)',
            display: 'grid',
            gridTemplateRows: 'auto 70px'
         }}
      >
         <div style={{ overflowY: 'auto' }}>
            {contentJSX}
         </div>
            
         <div style={{
            borderTop: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
            paddingRight: 30,
         }}>
            <IconButton onClick={this.fetchData} color="primary">
               <Refresh style={{ fontSize: 45 }} />
            </IconButton>
         </div>
         
      </div>
   }
}

export default Patient;