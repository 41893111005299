
import LooksOne from "@mui/icons-material/LooksOne";
import LooksTwo from "@mui/icons-material/LooksTwo";
import { MetaTags } from "react-meta-tags";
import actions from "../actions";
import PatientManager from "../components/PatientManager";
import SideMenu from "../components/SideMenu";
import LargeScreenOnlyPage from "./LargeScreenOnlyPage";


const menuItems = [
   {
      icon: LooksOne,
      caption: 'Patients',
      value: 'patients'
   },
   {
      icon: LooksTwo,
      caption: 'Update Password',
      value: 'update-password'
   },
]


class DoctorDashboard extends LargeScreenOnlyPage {

   state = {
      sideMenuValue: 'patients',
   }


   onSideMenuChange = (sideMenuValue) => {

      if (sideMenuValue === 'update-password')
         return actions.openUpdatePasswordModal();

      return this.updateState({ sideMenuValue });
   }



   __render() {

      return <>

         <MetaTags>
            <title>Doctor Dashboard | KureTeq</title>
         </MetaTags>

         <div
            style={{
               display: 'grid',
               gridTemplateColumns: '300px auto',
            }}
         >
            <div>
               <SideMenu
                  onChange={this.onSideMenuChange}
                  value={this.state.sideMenuValue}
                  items={menuItems}
               />
            </div>

            <div>

               <PatientManager />
               
            </div>
         </div>
      </>
      }
}

export default DoctorDashboard;