import swal from "sweetalert";
import actions from "./actions";
import { hideLoading, showLoading } from "./loading";
import request from './request'

function decodeJWT(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));

   return JSON.parse(jsonPayload);
}
function delay(millis) {
   return new Promise((resolve) => {
      setTimeout(resolve, millis);
   })
}

const YEAR_MILLIS = 365.25 * 24 * 3600 * 1000;

function calculateAge(date) {

   date = new Date(date);
   const today = new Date();

   const millis = today - date;
   const years = parseInt(millis / YEAR_MILLIS);

   return years;
}

function requestConfirmation({
   question,
   confirmButtonCaption='Yes',
   cancelButtonCaption="Cancel",
}) {
   return swal({
      text: question,
      buttons: [
         {
            text: confirmButtonCaption,
            value: true,
            className: "red-bg white-text",
            visible: true,
         },
         {
            text: cancelButtonCaption,
            value: false,
            visible: true,
            className: "white-bg primary-text"
         }
      ]
   });
}

async function scanQRCode() {

   await window.codescanner.start();
   const code = await window.codescanner.scan();
   await window.codescanner.stop();

   return code;
   
}

function getPatientIdentifierFromQrURL(url) {
   return url.split('/').reverse()[0];
}

async function logout() {
      
   try {

      showLoading();

      await request.delete('/api/login');
      window.App.redirect('/login');
      actions.setAuthenticated(false);
      
   } catch (err) {
      swal(String(err));
   } finally {
      hideLoading();
   }
}


export {
   calculateAge,
   decodeJWT,
   delay,
   getPatientIdentifierFromQrURL,
   logout,
   requestConfirmation,
   scanQRCode,
}