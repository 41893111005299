import Component from "@xavisoft/react-component";
import { connect } from "react-redux";

// helpers
function setDimensions() {
   const footer = document.getElementById('footer');
   
   const width = footer.scrollWidth + 'px';
   const height = footer.scrollHeight + 'px';

   document.documentElement.style.setProperty('--footer-width', width);
   document.documentElement.style.setProperty('--footer-height', height);
}

// component
class Footer extends Component {

   componentDidMount() {

      const resizeOberver = new window.ResizeObserver(setDimensions);
      const footer = document.getElementById('footer');
      resizeOberver.observe(footer);
      
      setDimensions();

   }

   render() {


      let jsx;
      const currentRoute = this.props.currentRoute || '/';

      if (
         (currentRoute.indexOf('-dashboard') > 0) ||
         (window.cordova && currentRoute === '/')
      ) {
         jsx = undefined;
      } else {
         jsx = <div
            style={{
               color: 'grey',
               padding: 20,
            }}
         >
            &copy; {new Date().getFullYear()} KureTeq. Developed by
            {" "}
            <a 
               href="//xavisoft.co.zw"
               style={{
                  color: 'var(--primary)',
                  textDecoration: 'none',
               }}
            >
               Xavisoft Digital
            </a>
         </div>
      }

      return <footer id="footer">
         {jsx}
      </footer>
   }
}


function mapStateToProps(state) {
   const { currentRoute } = state;
   return { currentRoute }
}

export default connect(mapStateToProps)(Footer);