import { MenuItem, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { MEDICAL_PRIVILEDGE_LEVELS } from "../constants";
import ResponsiveTable from "./ResponsiveTable";

const accessLevels = [
   {
      value: MEDICAL_PRIVILEDGE_LEVELS.EVERYONE,
      caption: 'Everyone',
   },
   {
      value: MEDICAL_PRIVILEDGE_LEVELS.MEDICAL_PERSONNEL,
      caption: 'Medical Personnel',
   },
]

function formatDataForResponsiveTable(props) {

   const columns =  [ 'DESCRIPTION', 'ACCESSIBLE TO',  ];

   const rows = props.medical_conditions.map(condition => {

      const { accessible_to, description, id } = condition;


      const accessibleToJSX = <TextField
         select
         variant="outlined"
         value={accessible_to}
         size="small"
         data-id={id}
         fullWidth={false}
         onChange={
            e => {
               props.onAccessibleToChange(id, e.target.value)
            }
         }
      >
         {
            accessLevels.map(accessLevel => {
               return <MenuItem value={accessLevel.value}>
                  {accessLevel.caption}
               </MenuItem>
            })
         }
      </TextField>

      return {
         description,
         accessibleToJSX,
      }
   });

   return { columns, rows }
}

export default class PatientDashboardMedicalConditionManager extends Component {

   render() {


      const noDataMessage = <p className="font-30 grey-text">
         No medical conditions
      </p>

      const { columns , rows } = formatDataForResponsiveTable(this.props);


      return <div className="fill-container" style={{ overflowY: 'auto' }}>

         <ResponsiveTable columns={columns} rows={rows} noDataMessage={noDataMessage} />

      </div>
   }
}