import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import Component from "@xavisoft/react-component";
import capitalize from "capitalize";
import QrCodeIcon from '@mui/icons-material/QrCode2';
import ResponsiveTable from "./ResponsiveTable";
import QrCode from '../components/QrCode';


function formatDataForResponsiveTable(prescriptions, actions) {

   const columns =  [ 'ID', 'PRESCRIBED BY', 'ITEMS', 'DISPENSED', '' ];

   const rows = prescriptions.map(prescription => {

      const { items, id, dispensed, doctor } = prescription;
      const prescribedBy = capitalize.words(`${doctor.name} ${doctor.surname}`);

      const actionJSX = <IconButton onClick={actions.showQR} data-id={id}>
         <QrCodeIcon />
      </IconButton>

      return {
         id,
         prescribedBy,
         items,
         dispensed: dispensed ? 'YES' : 'NO',
         actionJSX,
      }
   });

   return { columns, rows }
}

export default class PatientDashboardPrescriptions extends Component {

   state = {
      prescriptionIdForQR: null,
   }

   showPrescriptionQRCode = (event) => {
      const prescriptionIdForQR = event.currentTarget.getAttribute('data-id');
      this.updateState({ prescriptionIdForQR })
   }

   closeQrCodeModal = () =>{
      this.updateState({ prescriptionIdForQR: null });
   }
   render() {

      const { rows, columns } = formatDataForResponsiveTable(this.props.prescriptions, {
         showQR: this.showPrescriptionQRCode,
      });

      return <>
         <ResponsiveTable rows={rows} columns={columns} noDataMessage="No prescriptions" />

         <Dialog open={!!this.state.prescriptionIdForQR}>
            <DialogTitle>Prescription #{this.state.prescriptionIdForQR} QR Code</DialogTitle>

            <DialogContent>
               <QrCode code={this.state.prescriptionIdForQR || ''} />
            </DialogContent>

            <DialogActions>
               <Button onClick={this.closeQrCodeModal}>
                  CLOSE
               </Button>
            </DialogActions>
         </Dialog>
      </>
   }
}