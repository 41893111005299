import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ApartmentIcon from '@mui/icons-material/Apartment';


const CONTACT_TYPES = [
   {
      caption: 'WhatsApp',
      value: 'whatsapp',
      icon: WhatsAppIcon,
      inputType: 'number',
   },
   {
      caption: 'Address',
      value: 'address',
      icon: ApartmentIcon,
      inputType: 'text',
   },
   {
      caption: 'Phone',
      value: 'phone',
      icon: PhoneIcon,
      inputType: 'number',
   },
   {
      caption: 'Email',
      value: 'email',
      icon: AlternateEmailIcon,
      inputType: 'email',
   },
].sort((a, b) => {
   return a.caption > b.caption ? 1 : -1;
});


const ACTIONS = {
   CLOSE_UPDATE_PASSWORD_MODAL: 'close-update-password-modal',
   OPEN_UPDATE_PASSWORD_MODAL: 'open-update-password-modal',
   SET_AUTHENTICATED: 'set-authenticated',
   SET_CURRENT_ROUTE: 'set-current-route',
   SET_DEVICE_INFO: 'set-device-info',
   SET_SUBSCRIPTION_FEE: 'set-subscription-key',
}

const MEDICAL_PRIVILEDGE_LEVELS = {
   EVERYONE: 'everyone',
   MEDICAL_PERSONNEL: 'medical_personal'
}

export {
   ACTIONS,
   CONTACT_TYPES,
   MEDICAL_PRIVILEDGE_LEVELS,
}