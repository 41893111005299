import { Button, ClickAwayListener, Paper } from "@mui/material";
import Component from "@xavisoft/react-component";
import MenuIcon from '@mui/icons-material/Menu';
import { v4 as uuid } from 'uuid'
import { delay, logout, scanQRCode } from "../utils";
import { connect } from "react-redux";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import swal from "sweetalert";


function MenuItem(props) {

   const Icon = props.icon;
   let backgroundColor;

   if (props.active) {
      backgroundColor = 'var(--accent-neg-2)'
   } 

   return <div 
      className='v-align'
      data-value={props.value}
      onClick={props.onClick}
      style={{
         color: 'white',
         cursor: 'pointer',
         backgroundColor,
         borderRadius: 10,
      }}
   >
      <Icon
         style={{
            margin: 10,
         }}
      />

      <span
         style={{
            fontWeight: 'bold',
            fontSize: 13,
            display: 'inline-block',
            marginRight: 10,
         }}
         className="truncate-text"
      >
         {props.caption}
      </span>
   </div>
}

class SideMenu extends Component {

   id = 'div-' + uuid();

   state = {
      showContent: false
   }

   onChange = (event) => {
      const value = event.currentTarget.getAttribute('data-value');

      if (value === this.props.value)
         return;

      this.props.onChange(value);
   }

   toggleShowContent = () => {
      const showContent = !this.state.showContent;
      return this.updateState({ showContent });
   }

   onClick = async (event) => {
      if (event.currentTarget !==  event.target) {
         await delay(100);
         this.toggleShowContent();
      }
   }

   scanQR = async () => {

      if (typeof this.props.onScanPatientClicked === 'function')
         return this.props.onScanPatientClicked();

      try {
         const qrcode = await scanQRCode();

         try {
            const url = new window.URL(qrcode);
            const route = url.pathname;

            if (route.indexOf('/patients/') > 0) {
               window.App.redirect(route);
            } else {
               throw new Error('Invalid qrcode');
            }

         } catch (err) {
            return swal('Invalid healthband QR code');
         }

      } catch (err) {
         swal(String(err));
      }
   }

   componentDidUpdate(prevProps, prevState) {
      if (this.state.showContent && !prevState.showContent)
         document.getElementById(this.id).addEventListener('click', this.onClick);
   }

   render() {

      let backButtonAction, backButtonCaption;

      if (this.props.authenticated) {
         backButtonAction = logout;
         backButtonCaption = 'LOGOUT'
      } else {
         backButtonAction = () => window.history.back();
         backButtonCaption = 'BACK';
      }

      let scanQrJSX;

      if (window.cordova) {
         scanQrJSX = <div className="vh-align">
            <Button 
               startIcon={<QrCodeScannerIcon />}
               style={{
                  color: 'white',
                  marginBottom: 20,
               }}
               onClick={this.scanQR}
            >
               SCAN PATIENT
            </Button>
         </div>
      }

      const contentJSX = <Paper 
         elevation={2}
         style={{
            height: 'calc(var(--window-height) - var(--navbar-height))',
            maxWidth: 300,
            width: '100%',
            background: 'var(--primary)',
            fontFamily: 'Arial',
            position: 'relative',
            borderRadius: 0,
         }}
         id={this.id}
      >

         <div style={{ padding: 30 }}>
            <h1 style={{ color: 'white', }}>
               KureTeq
            </h1>

            {
               this.props.items.map(item => {
                  return <MenuItem
                     key={item.value}
                     caption={item.caption}
                     value={item.value}
                     icon={item.icon}
                     active={item.value === this.props.value}
                     onClick={this.onChange}
                  />
               })
            }
         </div>
            

         <div
            style={{
               position: 'absolute',
               bottom: 0,
               width: '100%',
               paddingBottom: 30,
            }}
         >
            {scanQrJSX}
            <div className="vh-align">
               <Button  
                  style={{
                     color: 'white',
                  }}
                  onClick={backButtonAction}
                  startIcon={<ArrowBackIosNew />}
               >
                  {backButtonCaption}
               </Button>
            </div>
         </div>
      </Paper>

      if (this.props.variant === 'temporary') {


         if (this.state.showContent) {
            return <div 
               style={{ 
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: 1,
               }}
            >
               <ClickAwayListener onClickAway={this.toggleShowContent}>
                  {contentJSX}
               </ClickAwayListener>
            </div>
         } else {
            return <Button onClick={this.toggleShowContent}>
               <MenuIcon />
            </Button>
         }
      } else {
         return contentJSX;
      }

   }
}

function mapStateToProps(state) {
   return {
      authenticated: state.authenticated
   }
}

export default connect(mapStateToProps)(SideMenu);