import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { css } from '@emotion/css';
import { errorToast } from '../toast';
import { hideLoading, showLoading } from "../loading";
import swal from "sweetalert";
import request from "../request";
import { delay } from "../utils";


const divFormClass = css({
   padding: 20,
   '& > *': {
      margin: '10px auto !important',
   },
   maxWidth: 400,
})


class EntityEditor extends Component {

   submit = async () => {

      // extract
      const txtName = document.getElementById('txt-name');
      const txtSurname = document.getElementById('txt-surname');
      const txtEmail = document.getElementById('txt-email');

      const name = txtName.value;
      const surname = txtSurname.value;
      const email = txtEmail.value;

      // presence check
      if (!name) {
         errorToast(`Provide ${this.props.entityName}'s name`);
         return txtName.focus();
      }

      if (!surname) {
         errorToast(`Provide ${this.props.entityName}'s surname`);
         return txtName.focus();
      }

      if (!email) {
         errorToast(`Provide ${this.props.entityName}'s email`);
         return txtEmail.focus();
      }

      // submit
      const baseUrl = `/api/admin/${this.props.entityUrl}`;
      let data, method, url;

      if (this.props.mode === 'edit') {
         const set = {};

         const extracted = { name, surname, email };

         [ 'name', 'surname', 'email' ].forEach(field => {
            if (extracted[field] !== this.props.data[field])
               set[field] = extracted[field];
         });

         if (Object.keys(set).length === 0) {
            return errorToast('No changes made');
         }

         data = { set };
         url = `${baseUrl}/${this.props.data.id}`;
         method = 'patch';

      } else {
         data = { name, surname, email };
         url = baseUrl;
         method = 'post'
      }

      try {

         showLoading();

         const res = await request[method](url, data);
         swal('Operation sucessful');

         let returnData;
         
         if (this.props.mode === 'edit') {
            returnData = data.set;
         } else {
            returnData = { ...data, ...res.data };
         }

         this.props.close(returnData);

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   async componentDidUpdate(prevProps) {

      if (this.props.open && !prevProps.open && this.props.data) {
         await delay(500);

         const { email, name, surname } = this.props.data;

         document.getElementById('txt-name').value = name;
         document.getElementById('txt-surname').value = surname;
         document.getElementById('txt-email').value = email;
      }
   }

   render() {
      return <Dialog open={this.props.open}>

         <DialogTitle>
            <h2 style={{ textTransform: 'capitalize' }}>
               {this.props.mode} {this.props.entityName}
            </h2>
         </DialogTitle>

         <DialogContent>
            <div className={divFormClass}>
               <TextField
                  id="txt-name"
                  autoFocus
                  fullWidth
                  variant="standard"
                  label="Name"
                  InputLabelProps={{
                     shrink: true
                  }}
               />

               <TextField
                  id="txt-surname"
                  fullWidth
                  variant="standard"
                  label="Surname"
                  InputLabelProps={{
                     shrink: true
                  }}
               />

               <TextField
                  id="txt-email"
                  fullWidth
                  variant="standard"
                  label="Email"
                  InputLabelProps={{
                     shrink: true
                  }}
                  type="email"
               />
            </div>
         </DialogContent>

         <DialogActions>

            <Button onClick={this.props.close}>
               CANCEL
            </Button>

            <Button variant="contained" onClick={this.submit}>
               SUBMIT
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default EntityEditor;