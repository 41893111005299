
import { Button } from "@mui/material";
import React from "react";
import logger from "../logger";



export default class ErrorBoundary extends React.Component {

   state = {
      hasError: false,
   }

   componentDidCatch(err) {
      this.setState({ hasError: true });
      logger.error(err);
   }

   render() {

      if (!!this.state.hasError) {
         return <ErrorMessage />
      } else {
         return this.props.children;
      }
   }
   
}

function reload() {
   window.location.reload();
}

function ErrorMessage() {
   return <div 
      style={{
         position: 'fixed',
         top: 0,
         left: 0,
         right: 0,
         bottom: 0,
      }}
      className="vh-align"
   >
      <div style={{ width: '100%', maxWidth: 400 }}>
         <p className="font-30 grey-text">
            Something went wrong 😔
         </p>

         <Button variant="contained" onClick={reload} size="large" style={{ backgroundColor: 'var(--primary)'}}>
            RELOAD
         </Button>
      </div>
   </div>
}