import { Button, Fab, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EntityEditor from "./EntityEditor";
import SlashStyleHeading from "./SlashStyleHeading";


class EntityManager extends Component {

   state = {
      entities: [],
      dataFetched: false,
      fetchError: false,
      entityEditorMode: null,
      entityBeingEdited: null
   }

   closeEntityEditor = (data) => {

      let entities;
      
      if (this.state.entityEditorMode === 'edit') {
         const id = this.state.entityBeingEdited.id;

         entities = this.state.entities.map(entity => {
            if (entity.id === id) {
               entity = {
                  ...entity,
                  ...data
               }
            }

            return entity;
         });
      } else {

         entities = [ ...this.state.entities ];

         if (!(data.currentTarget))
            entities.push(data);
         
      }

      return this.updateState({ 
         entities,
         entityBeingEdited: null,
         entityEditorMode: null,
      });

   }

   editEntity = (event) => {

      const id = parseInt(event.currentTarget.getAttribute('data-entity-id'));
      const [ entityBeingEdited ] = this.state.entities.filter(entity => entity.id === id);

      return this.updateState({
         entityBeingEdited,
         entityEditorMode: 'edit'
      });

   }

   addEntity = () => {
      return this.updateState({ entityEditorMode: 'add' });
   }

   deleteEntity = async (event) => {

      const id = parseInt(event.currentTarget.getAttribute('data-entity-id'));
      const [ entity ] = this.state.entities.filter(item => item.id === id);

      const confirm = await swal({
         text: `Do you really want to delete ${this.props.entityName} '${entity.name} ${entity.surname}'?`,
         buttons: [
            {
               text: 'Yes Delete',
               value: true,
               className: "red-bg white-text",
               visible: true,
            },
            {
               text: 'No, don\'t',
               value: false,
               visible: true,
               className: "white-bg primary-text"
            }
         ]
      });

     
      if (!confirm)
         return;

      try {

         showLoading();
         await request.delete(`/api/admin/${this.props.entityUrl}/${id}`);

         const entities = this.state.entities.filter(item => item.id !== id);

         await this.updateState({ entities });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }
   
   fetchData = async () => {
      
      try {

         showLoading();

         const url = `/api/admin/${this.props.entityUrl}`;
         const res = await request.get(url);
         const entities = res.data;

         await this.updateState({
            entities,
            dataFetched: true,
            fetchError: false,
         });

      } catch (err) {
         swal(String(err));
         await this.updateState({ dataFetched: false, fetchError: true });
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {
      if (prevProps.entityUrl !== this.props.entityUrl)
         await this.fetchData();
   }

   componentDidMount() {
      this.fetchData();
   }

   render() {

      let jsx;

      if (this.state.dataFetched) {

         let noData, data;

         if (this.state.entities.length > 0) {

            data = this.state.entities.map(entity => {
               const { id, name, surname, email } = entity;

               return <TableRow>
                  <TableCell>{name} {surname}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>
                     <Button data-entity-id={id} onClick={this.editEntity}>
                        <EditIcon />
                     </Button>
                     <Button data-entity-id={id} onClick={this.deleteEntity}>
                        <DeleteIcon style={{ color: 'red' }} />
                     </Button>
                  </TableCell>
               </TableRow>
            });

         } else {
            noData = <p className="grey-text" style={{ fontSize: 30 }}>
               No {this.props.entityGroupName} yet.
            </p>
         }

         jsx = <div
            style={{
               display: 'grid',
               gridTemplateRows: '70px auto 70px',
               height: 'var(--window-height)',
            }}
         >

            <EntityEditor
               open={!!this.state.entityEditorMode}
               data={this.state.entityBeingEdited}
               entityUrl={this.props.entityUrl}
               mode={this.state.entityEditorMode}
               close={this.closeEntityEditor}
               entityName={this.props.entityName}
            />

            <div className="valign" style={{ paddingLeft: 20 }}>
               <SlashStyleHeading biggerText="ADMIN DASHBOARD" smallerText={this.props.entityGroupName} />
            </div>

            <div style={{ overflowY: 'auto' }}>
               <Table>

                  <TableHead>
                     <TableRow>
                        <TableCell><b>NAME</b></TableCell>
                        <TableCell><b>EMAIL</b></TableCell>
                        <TableCell><b></b></TableCell>
                     </TableRow>
                  </TableHead>

                  <TableBody>
                     {data}
                  </TableBody>
               </Table>
               {noData}
            </div>

            <div
               style={{
                  borderTop: '1px solid #ccc',
                  justifyContent: 'right',
               }}
               className="v-align"
            >
               <Fab
                  variant="contained"
                  style={{
                     color: 'var(--primary)'
                  }}
                  size="small"
                  onClick={this.fetchData}
               >
                  <RefreshIcon />
               </Fab>

               <Fab
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{
                     margin: 'auto 15px'
                  }}
                  onClick={this.addEntity}
               >
                  <AddIcon />
               </Fab>
            </div>
         </div>
      } else {
         jsx = <div className="fill-container vh-align">
            <p>

               Something wrong happened<br />

               <Button onClick={this.fetchData}>
                  RETRY
               </Button>
            </p>
         </div>
      }
      
      return jsx;
   }
}

export default EntityManager;