
import { HashLink } from "react-router-hash-link";
import RoundedButton from "./RoundedButton";


function GetStartedButton(props) {

   let padding;

   if (props.padding) {
      padding = {
         '--padding': props.padding,
         paddingLeft: 'var(--padding)',
         paddingRight: 'var(--padding)',
      }
   } else {
      padding = {}
   }
   return <HashLink 
      to="/signup"
      style={{
         textDecoration: 'none'
      }}
   >
      <RoundedButton 
         variant="contained"
         style={{
            // '--padding': props.padding,
            // paddingLeft: 'var(--padding)',
            // paddingRight: 'var(--padding)',
            ...padding,
            ...(props.style || {}),
         }}
      >
         {props.children || props.caption || 'GET STARTED'}
      </RoundedButton>
   </HashLink>
}

export default GetStartedButton;