import { ACTIONS } from "./constants";

function setDeviceInfoReducer(state, device) {
   return { ...state, device };
}

function setCurrentRouteReducer(state, payload) {
   const { route:currentRoute } = payload;
   return { ...state, currentRoute };
}

function setUpdatePasswordModalOpenStateReducer(state, updatePasswordModalOpen) {
   return { ...state, updatePasswordModalOpen };
}

function setSubscriptionFeeReducer(state, subscriptionFee) {
   return { ...state, subscriptionFee };
}

function setAuthenticatedReducer(state, {authenticated, dashboardRoute }) {
   return { ...state, authenticated, dashboardRoute };
}



function reducer(state, action) {

   const { type, payload } = action;

   switch (type) {

      case ACTIONS.SET_DEVICE_INFO:
         return setDeviceInfoReducer(state, payload);

      case ACTIONS.SET_CURRENT_ROUTE:
         return setCurrentRouteReducer(state, payload);

      case ACTIONS.OPEN_UPDATE_PASSWORD_MODAL:
         return setUpdatePasswordModalOpenStateReducer(state, true);

      case ACTIONS.CLOSE_UPDATE_PASSWORD_MODAL:
         return setUpdatePasswordModalOpenStateReducer(state, false);

      case ACTIONS.SET_SUBSCRIPTION_FEE:
         return setSubscriptionFeeReducer(state, payload.subscriptionFee);
      
      case ACTIONS.SET_AUTHENTICATED:
         return setAuthenticatedReducer(state, payload);

      default:
         console.info('Invalid action type:', type);
         return state;
   }
}


export default reducer;