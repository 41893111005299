
import capitalize from 'capitalize';
import Component from '@xavisoft/react-component';
import HorizontalTable from './HorizontalTable';
import { calculateAge } from '../utils';
import { Button } from '@mui/material';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';


function ContactInfo(props) {
   return <div
      style={{
         display: 'grid',
         gridTemplateColumns: '1fr 2fr',
         borderBottom: '1px solid #ddd',
         padding: '10px 15px 20px 15px',
      }}
   >
      <b>{props.type.toUpperCase()}</b>
      <div>
         <div>{props.data}</div>
         <div style={{ fontWeight: '600', marginTop: 5, }} className="grey-text">
            ({props.description})
         </div>
      </div>
   </div>
}


export default class ScannedPatient extends Component {

   render() {


      let contactInfoJSX;

      if (!this.props.data)
         return;

      if (this.props.data.contact_info.length) {
         contactInfoJSX = this.props.data.contact_info.map(contact => {
            return <ContactInfo {...contact} />
         });
      } else {
         contactInfoJSX = <p className='grey-text' style={{ margin: 15 }}>
            No emergency contacts listed.
         </p>
      }

      let medicalConditionsJSX;

      if (this.props.data.medical_conditions.length) {
         medicalConditionsJSX = this.props.data.medical_conditions.map(condition => {
            return <div style={{ padding: 15, borderBottom: '1px solid #ccc' }}>
               {condition.description}
            </div>
         });
      } else {
         medicalConditionsJSX = <p className='grey-text' style={{ margin: 15 }}>
            No medical conditions accessible.
         </p>
      }

      const { name, surname, sex, dob } = this.props.data;
      const age = calculateAge(dob);
      const fullName = capitalize.words(`${name} ${surname}`)

      return <div
         style={{
            height: 'var(--window-height)',
            display: 'grid',
            gridTemplateRows: '70px auto 60px',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            background: 'white',
         }}
      >
         <div style={{ borderBottom: '1px solid #ddd', paddingLeft: 15 }} className="valign">
            <h1>{fullName}</h1>
         </div>

         <div style={{ overflowY: 'auto' }}>

            <HorizontalTable data={{ AGE: age, SEX: capitalize(sex) }} />

            <h2 className='grey-text' style={{ marginLeft: 15 }}>Emergency contacts</h2>
            {contactInfoJSX}

            <h2 className='grey-text' style={{ marginLeft: 15 }}>Medical conditions</h2>
            {medicalConditionsJSX}

         </div>

         <div className='valign' style={{ justifyContent: 'right', paddingRight: 30 }}>
            <Button startIcon={<ArrowBackIosNew />} onClick={this.props.close}>
               BACK
            </Button>
         </div>
      </div>
   }
}