
import Looks3 from "@mui/icons-material/Looks3";
import Looks4 from "@mui/icons-material/Looks4";
import LooksOne from "@mui/icons-material/LooksOne";
import LooksTwo from "@mui/icons-material/LooksTwo";
import AdminMoreSection from "../components/AdminMoreSection";
import EntityManager from "../components/EntityManager";
import SideMenu from "../components/SideMenu";
import LargeScreenOnlyPage from "./LargeScreenOnlyPage";
import { MetaTags } from "react-meta-tags";


const menuItems = [
   {
      icon: LooksOne,
      caption: 'Doctors',
      value: 'doctors'
   },
   {
      icon: LooksTwo,
      caption: 'Pharmacists',
      value: 'pharmacists'
   },
   {
      icon: Looks3,
      caption: 'Emergency Responders',
      value: 'emergency-responders',
   },
   {
      icon: Looks4,
      caption: 'More...',
      value: 'more',
   },
]


class AdminDashboard extends LargeScreenOnlyPage {

   state = {
      sideMenuValue: 'doctors'
   }

   onSideMenuChange = (sideMenuValue) => {
      return this.updateState({ sideMenuValue });
   }

   __render() {

      let entityUrl, entityGroupName, entityName;
      let extras;

      switch (this.state.sideMenuValue) {

         case 'doctors':
            entityUrl = 'doctors';
            entityGroupName ='doctors';
            entityName = 'doctor';
            break;

         case 'pharmacists':
            entityUrl = 'pharmacists';
            entityGroupName ='pharmacists';
            entityName = 'pharmacist';
            break;

         case 'emergency-responders':
            entityUrl = 'emergency-responders';
            entityGroupName ='emergency responders';
            entityName = 'emergency responder';
            break;

         case 'more':
            extras = true;
            break;

         default:
            return <p>Something wrong with the code</p>
      }

      let contentJSX;

      if (extras) {

         contentJSX = <AdminMoreSection />

      } else {
         contentJSX = <EntityManager
            entityUrl={entityUrl}
            entityGroupName={entityGroupName}
            entityName={entityName}
         />
      }

      return <>
         <MetaTags>
            <title>Admin Dashboard | KureTeq</title>
         </MetaTags>

         <div
            style={{
               display: 'grid',
               gridTemplateColumns: '300px auto',
            }}
         >
            <div>
               <SideMenu
                  onChange={this.onSideMenuChange}
                  value={this.state.sideMenuValue}
                  items={menuItems}
               />
            </div>

            <div>
               {contentJSX}
            </div>
         </div>
      </>
   }
}

export default AdminDashboard;