
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Component from "@xavisoft/react-component";
import { connect } from "react-redux";


class ResponsiveTable extends Component {


   render() {

      let jsx;

      if (this.props.rows.length) {
         if (['xs', 'sm'].includes(this.props.device.size)) {
            jsx =  this.props.rows.map(row => {
               return <Paper elevation={8} style={{margin: 20, }}>
                  <Table>
                     <TableBody>
                        {
                           Object.values(row).map((value, i) => {
                              return <TableRow>
                                 <TableCell><b>{this.props.columns[i]}</b></TableCell>
                                 <TableCell>
                                    <div className="right-align">
                                       {value}
                                    </div>
                                 </TableCell>
                              </TableRow>
                           })
                        }
                     </TableBody>
                  </Table>
               </Paper>
            }); 
         } else {
            jsx = <Table className="mui-table">
               <TableHead>
                  <TableRow>
                     {
                        this.props.columns.map(column => {
                           return <TableCell>{column}</TableCell>
                        })
                     }
                  </TableRow>
               </TableHead>

               <TableBody>
                  {
                     this.props.rows.map(row => {
                        return <TableRow>
                           {
                              Object.values(row).map(value => {
                                 return <TableCell>{value}</TableCell>
                              })
                           }
                        </TableRow>
                     })
                  }
               </TableBody>
            </Table>
         }
      } else {
         jsx = <p className="font-30 grey-text" style={{ padding: '0 15px'  }}>
            {this.props.noDataMessage || 'No data'} 
         </p>
      }

      return jsx;
   }
}

function mapStateToProps(state) {
   return { device: state.device }
}

export default connect(mapStateToProps)(ResponsiveTable)