
function SlashStyleHeading(props) {
   return <div>
   <h1 
      style={{ 
         display: 'inline-block'
      }}
   >
      {props.biggerText}
   </h1>
   <span 
      style={{ 
         color: 'grey', 
         textTransform: 'uppercase',
         fontWeight: 'bold'
      }}
   >
      {" "}/ {props.smallerText}
   </span>
</div>
}

export default SlashStyleHeading;