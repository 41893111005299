

import { Button } from '@mui/material';
import { logout } from '../utils';
import Page from './Page';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getDeviceInfo } from '../device';


class LargeScreenOnlyPage extends Page {

   __render() {
      return <>Please implement <code>largeScreenOnlyPage.__render()</code></>
   }

   onScreenResize = () => {
      this.forceUpdate();
   }


   componentDidMount() {
      super.componentDidMount();
      window.addEventListener('resize', this.onScreenResize);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.onScreenResize);
   }

   _render() {

      const { type, orientation } = getDeviceInfo();

      if (type === 'phone' || (type === 'tablet' && orientation === 'portrait' )) {

         let message, actionJSX;

         if (type === 'phone') {
            message = "This page doesn't support smaller screen. Please use a large screen tablet(in landscape mode) or a laptop.";

            actionJSX = <Button onClick={logout} startIcon={<ArrowBackIcon />}>
               LOGOUT
            </Button>;

         } else {
            message = 'Rotate the tablet to landscape orientation';
         }

         return <div className='vh-align' style={{ height: 'var(--window-height)' }}>
            <div style={{ margin: 20, width: '100%', maxWidth: 400 }}>
               <p className='grey-text' style={{ fontSize: 24 }}>
                  {message}
               </p>

               {actionJSX}
            </div>
         </div>
      }

      return this.__render();
   }
}


export default LargeScreenOnlyPage;