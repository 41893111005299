import { connect } from "react-redux";
import Page from "./Page";
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import swal from 'sweetalert';
import { hideLoading, showLoading } from '../loading';
import { errorToast } from '../toast';
import { logout, scanQRCode } from '../utils';
import request from '../request';
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import PharmacistDashboardPrescription from "../components/PharmacistDashboardPrescription";
import { MetaTags } from "react-meta-tags";
import actions from "../actions";
import LooksOne from "@mui/icons-material/LooksOne";
import LooksTwo from "@mui/icons-material/LooksTwo";
import SideMenu from "../components/SideMenu";

const tabs = [
   {
      icon: LooksOne,
      caption: 'Verify prescription',
      value: 'verify-prescription'
   },
   {
      icon: LooksTwo,
      caption: 'Update Password',
      value: 'update-password'
   },
];

class PharmacistDashboard extends Page {

   state = {
      prescription: null,
      tab: 'verify-prescription'
   }

   onTabChange = (tab) => {
      if (tab === 'update-password')
         return actions.openUpdatePasswordModal();
   }


   onBtnCheckClick = () => {

      const txtPrescriptionId = document.getElementById('txt-prescription-id');
      const prescriptionId = txtPrescriptionId.value;

      if (!prescriptionId) {
         errorToast('Enter prescription ID');
         return txtPrescriptionId.focus();
      }

      this.verifyPrescription(prescriptionId);

   }

   scanPrescription = async () => {
      try {
         const prescriptionId = await scanQRCode();
         this.verifyPrescription(prescriptionId)
      } catch (err) {
         swal(String(err));
      }

   }

   updatePrescription = async (update) => {
      const prescription = { ...this.state.prescription, ...update };
      return this.updateState({ prescription });
   }

   verifyPrescription = async (prescriptionId) => {

      try {
         showLoading();

         const res = await request.get(`/api/pharmacist/prescriptions/${prescriptionId}`);
         const prescription = { ...res.data, id: prescriptionId };

         this.updateState({ prescription });
         document.getElementById('txt-prescription-id').value = '';

      } catch (err) {
         swal(String(err))
      } finally {
         hideLoading();
      }
   }

   closePrescription = () => {
      return this.updateState({ prescription: null });
   }

   _render() {

      let jsx;

      const searchByPrescriptionJSX = <div
         style={{
            display: 'grid',
            gridTemplateColumns: 'auto 100px',
            columnGap: 10,
         }}
      >
         <div>
            <TextField
               id="txt-prescription-id"
               type={"number"}
               placeholder="Prescription ID"
               variant="outlined"
               size="small"
               fullWidth
            />
         </div>

         <div>
            <Button variant="contained" size="medium" fullWidth onClick={this.onBtnCheckClick}>
               CHECK
            </Button>
         </div>
      </div>

      if (window.cordova) {

         jsx = <>
            {searchByPrescriptionJSX}

            <OR />

            <Button size="large" fullWidth variant="contained" onClick={this.scanPrescription}>
               SCAN PRESCRIPTION
            </Button>
         </>

      } else {
         jsx = searchByPrescriptionJSX;
      }

      const isScreenSizeSmall = [ 'xs', 'sm' ].includes(this.props.device.size);

      let titleJSX, logoutJSX;
      const textTitleJSX = <h2 style={{ marginLeft: 20 }}>Verify Prescription</h2>

      if (isScreenSizeSmall) {
         titleJSX = <>
            <SideMenu items={tabs} value={this.state.tab} onChange={this.onTabChange} variant="temporary" />
            {textTitleJSX}
         </>

         logoutJSX = <div 
            style={{
               position: 'absolute',
               right: 30,
               bottom: 30,
            }}
         >
            <Button startIcon={<BackIcon />} onClick={logout}>
               LOGOUT
            </Button>
         </div>
      } else {
         titleJSX = textTitleJSX;
      }

      let contentJSX = <>
         <div
            style={{
               height: 'var(--window-height)',
               display: 'grid',
               gridTemplateRows: '60px auto'
            }}
         >
            <div 
               className="valign"
               style={{
                  borderBottom: '1px solid #ccc'
               }}
            >
               {titleJSX}
            </div>

            <div className="vh-align" style={{ position: 'relative' }}>
               <div style={{ width: '100%', maxWidth: 400, padding: 20 }}>
                  <h1 className="center-align">Verify Prescription</h1>
                  <div style={{ borderBottom: '2px solid var(--primary)', paddingBottom: 20, }}>
                     {jsx}
                  </div>
               </div>

               {logoutJSX}
               
            </div>
         </div>
      
         <PharmacistDashboardPrescription
            data={this.state.prescription}
            close={this.closePrescription}
            update={this.updatePrescription}
         />
      </>;

      if (!isScreenSizeSmall) {
         contentJSX = <div
            style={{
               display: 'grid',
               gridTemplateColumns: '300px auto',
            }}
         >
            <div>
               <SideMenu items={tabs} value={this.state.tab} onChange={this.onTabChange} />
            </div>
            
            {contentJSX}
         </div>
      }

      return <>

         <MetaTags>
            <title>Pharmacist Dashboard | KureTeq</title>
         </MetaTags>

         {contentJSX}

      </>
   }
}

function OR() {

   const lineJSX = <div>
      <div className="v-align fill-container">
         <div style={{ height: 1, backgroundColor: 'gray', width: '100%' }} />
      </div>
   </div> 
   return <div
      style={{
         display: 'grid',
         gridTemplateColumns: 'auto 50px auto',
         columnGap: 20,
      }}
   >
      {lineJSX}

      <div className="center-align">
         <h2>OR</h2>
      </div>

      {lineJSX}
   </div>
}


function mapStateToProps(state) {
   return { device: state.device }
}

export default connect(mapStateToProps)(PharmacistDashboard);