import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";


class PasswordResetModal extends Component {

   submit = async () => {

      // extract
      const txtEmail = document.getElementById('txt-reset-email');
      const email = txtEmail.value;

      // validated
      if (!email) {
         errorToast('Provide your account email');
         return txtEmail.focus();
      }

      // submit
      try {

         showLoading();

         await request.post('/api/general/password/reset', { email });
         await swal('Your password has been reset. Check your email for a new password');

         this.props.close();

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {
      return <Dialog open={this.props.open}>
         <DialogTitle>RESET PASSWORD</DialogTitle>

         <DialogContent>
            <TextField
               id="txt-reset-email"
               label="Your email"
               size="small"
               variant="standard"
               fullWidth
               type="email"
            />
         </DialogContent>

         <DialogActions>
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
            <Button variant="contained" onClick={this.submit}>
               RESET
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default PasswordResetModal;