

const palette = {
   '--accent-neg-2': '#176922',
   '--accent-neg-1': '#6BFF7F',
   '--primary': '#3ab64a',
   '--accent-1': '#690D25',
   '--accent-2': '#B53A5A',
}


Object.keys(palette).forEach(key => {
   document.documentElement.style.setProperty(key, palette[key]);
});


export default palette;