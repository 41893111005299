
import Page from './Page';
import { css } from '@emotion/css';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { errorToast } from '../toast';
import { hideLoading, showLoading } from '../loading';
import request from '../request';
import swal from 'sweetalert';
import { decodeJWT } from '../utils';
import { HashLink } from 'react-router-hash-link';
import PasswordResetModal from '../components/PasswordResetModal';
import actions from '../actions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { MetaTags } from 'react-meta-tags';


const divloginCss = css({
   maxWidth: 400,
   border: '1px solid #ddd',
   padding: 20,

   '& > *': {
      margin: '10px auto !important',
   },

   '& a': {
      color: '#00e',
      textUnderlineOffset: 3,
   }
})


class Login extends Page {

   state = {
      passwordResetModalOpen: false
   }

   openPasswordResetModal = () => {
      return this.updateState({ passwordResetModalOpen: true });
   }

   closePasswordResetModal = () => {
      return this.updateState({ passwordResetModalOpen: false });
   }

   login = async () => {

      const txtEmail = document.getElementById('txt-email');
      const txtPassword = document.getElementById('txt-password');

      const email = txtEmail.value;
      const password = txtPassword.value;

      if (!email) {
         errorToast('Provide email');
         return txtEmail.focus();
      }

      if (!password) {
         errorToast('Provide password');
         return txtPassword.focus();
      }

      const data = { email, password };

      try {

         showLoading();

         const res  =await request.post('/api/login', data);

         const accessToken = res.headers['openstack-xavisoft-access-token'];
         const userInfo = decodeJWT(accessToken);

         let dashboardRoute;

         switch (userInfo.user.account_type) {
            
            case 'admin':
            case 'super_admin':
               dashboardRoute = '/admin-dashboard';
               break;

            case 'doctor':
               dashboardRoute = '/doctor-dashboard';
               break;

            case 'pharmacist':
               dashboardRoute = '/pharmacist-dashboard';
               break;

            case 'er':
               dashboardRoute = '/er-dashboard';
               break;
               

            case 'patient':
               dashboardRoute = '/patient-dashboard';
               break;

            default:
               return;
         }

         window.App.redirect(dashboardRoute)
         actions.setAuthenticated(true, dashboardRoute);

      } catch (err) {
         console.log(err);
         swal(String(err));
      } finally {
         hideLoading();
      }
      
   }

   _render() {

      let skipJSX;

      if (window.cordova) {
         skipJSX = <Button 
            endIcon={<ArrowForwardIosIcon />}
            component={HashLink}
            to="/er-dashboard"
            style={{
               position: 'absolute',
               bottom: 20,
               right: 20,
            }}
         >
            SKIP
         </Button>
      }

      return <>

         <MetaTags>
            <title>Login | KureTeq</title>
         </MetaTags>

         <div className="vh-align page-size">
            <div className={divloginCss}>

               <h4>LOGIN</h4>

               <TextField
                  fullWidth
                  label="Email"
                  id='txt-email'
                  size='small'
                  variant='standard'
                  type="email"
               />
               
               <TextField
                  fullWidth
                  label="Password"
                  id='txt-password'
                  size='small'
                  variant='standard'
                  type="password"
               />

               <Button
                  fullWidth
                  size="large"
                  variant='contained'
                  onClick={this.login}
                  className="primary-bg"
               >LOGIN</Button>

               <div className='center-align'>
                  Don't have an account?
                  {" "}
                  <HashLink to="/signup">Sign Up</HashLink>
               </div>

               <div className='center-align'>
                  <HashLink to="" onClick={this.openPasswordResetModal}>
                     I forgot my password
                  </HashLink>
                  <PasswordResetModal 
                     open={this.state.passwordResetModalOpen}
                     close={this.closePasswordResetModal}
                  />
               </div>

            </div>

            {skipJSX}
         </div>
      </>
   }
}

export default Login;