import { Button, Fab, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import { calculateAge, requestConfirmation } from "../utils";
import SlashStyleHeading from "./SlashStyleHeading";
import ViewIcon from '@mui/icons-material/Visibility';
import RemoveIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EnlistPatient from "./EnlistPatient";
import Patient from "./Patient";
import request from "../request";


class PatientManager extends Component {

   state = {
      patients: [],
      dataFetched: false,
      enlistPatientModalOpen : false,
      patientInView: null
   }

   openEnlistPatientModal = () => {
      return this.updateState({ enlistPatientModalOpen: true });
   }

   closeEnlistPatientModal = (data) => {

      data.enlistment_id = data.id;

      const update = { enlistPatientModalOpen: false };

      if (!data.currentTarget) {
         update.patients = [ ...this.state.patients, data ]
      }

      return this.updateState(update);
   }


   removePatient = async (event) => {

      const enlistment_id = parseInt(event.currentTarget.getAttribute('data-enlistment-id'));
      const [ patient ] = this.state.patients.filter(item => item.enlistment_id === enlistment_id);

      const confirmButtonCaption = 'Yes, Delist';
      const question = `Do you really want to delist patient '${patient.national_id}'?`;
      const confirm = await requestConfirmation({ question, confirmButtonCaption });

      if (!confirm)
         return;

      try {

         showLoading();
         await request.delete(`/api/doctor/patient-enlistments/${enlistment_id}`);

         const patients = this.state.patients.filter(item => item.enlistment_id !== enlistment_id);
         await this.updateState({ patients });

         swal('Patient delisted');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   viewPatient = (event) => {
      const id = parseInt(event.currentTarget.getAttribute('data-patient-id'));
      const [ patientInView ] = this.state.patients.filter(item => item.id === id);

      return this.updateState({ patientInView });
   }

   closePatientView = () => {
      return this.updateState({ patientInView: null });
   }

   fetchPatients = async () => {

      try {

         showLoading();

         const res = await request.get('/api/doctor/patients');
         const patients = res.data;

         this.updateState({ patients, dataFetched: true });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   componentDidMount() {
      this.fetchPatients();
   }

   render() {

      let contentJSX;

      if (this.state.patientInView) {
         contentJSX = <Patient
            close={this.closePatientView}
            id={this.state.patientInView.id}
         />
      } else {
         if (this.state.dataFetched) {

            let noDataJSX, dataJSX;

            if (this.state.patients.length) {
               dataJSX = this.state.patients.map(patient => {

                  const { id, approved, sex, dob, national_id, enlistment_id } = patient;
                  const age = calculateAge(dob);
                  const name = `${patient.name || ''} ${patient.surname || ''}`.trim();

                  let viewJSX;

                  if (approved) {
                     viewJSX = <Button
                        color="secondary"
                        size="small"
                        data-patient-id={id}
                        onClick={this.viewPatient}
                     >
                        <ViewIcon />
                     </Button>
                  }

                  return <TableRow>

                     <TableCell>
                        <span style={{ textTransform: 'capitalize'} }>
                           {name || <>&mdash;</>}
                        </span>
                     </TableCell>

                     <TableCell>{national_id}</TableCell>


                     <TableCell>{approved ? 'YES': 'NO' }</TableCell>
                     
                     <TableCell>
                        <span style={{ textTransform: 'capitalize'} }>
                           {sex || <>&mdash;</>}
                        </span>
                     </TableCell>

                     <TableCell>{age || <>&mdash;</>}</TableCell>

                     <TableCell>
                        {viewJSX}
                        <Button data-enlistment-id={enlistment_id} onClick={this.removePatient} color="error" size="small">
                           <RemoveIcon />
                        </Button>
                     </TableCell>

                  </TableRow>
               });
            } else {
               noDataJSX = <p style={{ color: 'grey', fontSize: 30, marginLeft: 20 }}>
                  No patients enlisted yet.
               </p>
            }

            contentJSX = <>

               <Table>

                  <TableHead>
                     <TableRow>
                        <TableCell><b>NAME</b></TableCell>
                        <TableCell><b>NATIONAL_ID</b></TableCell>
                        <TableCell><b>APPROVED</b></TableCell>
                        <TableCell><b>SEX</b></TableCell>
                        <TableCell><b>AGE</b></TableCell>
                        <TableCell><b></b></TableCell>
                     </TableRow>
                  </TableHead>

                  <TableBody>
                     {dataJSX}
                  </TableBody>
               </Table>
               {noDataJSX}
            </>

         } else {
            contentJSX = <div className="fill-container vh-align">
               <div>

                  <p style={{ color: 'grey', fontSize: 30 }}>
                     Something went wrong
                  </p>

                  <Button onClick={this.fetchPatients}>
                     RETRY
                  </Button>

               </div>
            </div>
         }

         contentJSX = <>
            <div className="valign" style={{ paddingLeft: 20 }}>
               <SlashStyleHeading biggerText="DOCTOR DASHBOARD" smallerText="PATIENTS" />
            </div>
            
            <div style={{ overflowY: 'auto' }}>
               {contentJSX}
            </div>

            <div
               className="valign"
               style={{
                  justifyContent: 'right',
                  borderTop: '1px solid #ccc'
               }}
            >
               <IconButton onClick={this.fetchPatients} color="primary">
                  <RefreshIcon style={{ fontSize: 45 }} />
               </IconButton>

               <Fab 
                  variant="contained"
                  size="small"
                  color="primary"
                  style={{
                     margin: 'auto 10px'
                  }}
                  onClick={this.openEnlistPatientModal}
               >
                  <AddIcon />
               </Fab>

               <EnlistPatient
                  open={this.state.enlistPatientModalOpen}
                  close={this.closeEnlistPatientModal}
               />

            </div>
      </>
      }

      return <div 
         style={{
            height: 'var(--window-height)',
            display: 'grid',
            gridTemplateRows: '70px auto 70px',
         }}
      >
         {contentJSX}
      </div>
   }
}


export default PatientManager;