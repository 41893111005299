import { Button } from "@mui/material";
import Page from "./Page";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RefreshIcon from '@mui/icons-material/Refresh';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { HashLink } from "react-router-hash-link";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { MetaTags } from "react-meta-tags";

export default class VerifyPatientAccount extends Page {

   state = {
      success: false,
      message: ''
   }


   verify = async () => {

      let message, success;

      try {
         showLoading();

         const url = window.location.pathname.replace('/verification', '/api/patient/verification');
         await request.get(url);

         message = 'Your account have been successfully activated';
         success = true;
      } catch (err) {
         message = String(err);
         success = false;
      } finally {
         hideLoading();
      }

      await this.updateState({ message, success });
   }

   componentDidMount() {
      this.verify();
   }

   _render() {

      let actionsJSX;

      if (this.state.success) {
         actionsJSX = <Button 
            variant="contained" 
            startIcon={<LockOpenIcon />} 
            component={HashLink}
            to="/login"
         >
            LOGIN
         </Button>
      } else {
         actionsJSX = <>
            <Button 
               variant="contained" 
               startIcon={<RefreshIcon />} 
               onClick={this.verify}
            >
               RETRY
            </Button>

            <Button 
               variant="outlined" 
               startIcon={<PersonAddIcon />}
               onClick={this.verify}
               component={HashLink}
               to="/signup"
               style={{ marginLeft: 20 }}
            >
               SIGN UP
            </Button>
         </>
      }

      return <>
         <MetaTags>
            <title>Verify Account | KureTeq</title>
         </MetaTags>

         <div className="vh-align" style={{ height: 'calc(var(--window-height) - var(--navbar-height))' }}>
            <div style={{ width: '100%', maxWidth: 400, paddingBottom: 20, borderBottom: '2px var(--primary) solid', margin: 20 }}>
               <p className="grey-text" style={{ fontSize: 24 }}>{this.state.message}</p>
               {actionsJSX}
            </div>
         </div>
      </>
    }
}