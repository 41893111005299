
import { Button, Grid, Hidden, Typography } from '@mui/material';
import Component from '@xavisoft/react-component';
import { HashLink, HashLink as Link } from 'react-router-hash-link';
import unconsious from '../media/img/unconcious.png'
import GetStartedButton from './GetStartedButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


class Hero extends Component {
   
   render() {

      let actionsJSX, scanJSX;

      if (window.cordova) {
         actionsJSX = <div style={{ display: 'grid', gridTemplateColumns: 'auto auto', columnGap: 20 }}>
            <Button
               style={{
                  borderRadius: 25,
                  height: 50,
               }}
               variant="contained"
               component={HashLink}
               to='/login'
               fullWidth
            >
               LOGIN
            </Button>

            <Button
               style={{
                  borderRadius: 25,
                  height: 50,
               }}
               variant="outlined"
               component={HashLink}
               to='/signup'
               fullWidth
            >
               CREATE ACCOUNT
            </Button>
         </div>

         scanJSX = <Button 
            endIcon={<ArrowForwardIosIcon />}
            style={{
               position: 'fixed',
               bottom: 30,
               right: 30
            }}
            component={HashLink}
            to="/er-dashboard"
         >
            SCAN PATIENT
         </Button>
      } else {
         actionsJSX = <>
            <GetStartedButton 
               style={{ 
                  height: 50, 
                  fontSize: 15 
               }}
               padding={'30px'}
            />

            <Link
               to="/#about"
               style={{
                  color: 'var(--primary)',
                  display: 'inline-block',
                  marginLeft: 20,
                  textUnderlineOffset: 3,
               }}
            >
               LEARN MORE
            </Link>
         </>
      }
      
      return <section id='hero'>
         <Grid container>
            <Grid 
               item 
               xs={12} 
               md={6}
               style={{
                  height: 'calc(var(--window-height) - var(--navbar-height))'
               }}
            >
               <div className='vh-align fill-container'>
                  <div style={{ 
                     maxWidth: 400, 
                     width: '100%',
                     padding: 20
                  }}>

                     <Typography 
                        variant='h3' 
                        style={{
                           fontWeight: 'bold',
                           color: 'var(--accent-neg-2)',
                        }}
                     >
                        Let us know how to help you in case of a health emergency
                     </Typography>

                     <div style={{ margin: '20px auto'}}>
                        {actionsJSX}
                     </div>
                        
                  </div>
               </div>
               
            </Grid>

            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item xs={6}>
                  <div
                     className='fill-container'
                     style={{
                        background: `url(${unconsious})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '50% 50%'
                     }}
                  />
               </Grid>
            </Hidden>
         </Grid>

         {scanJSX}

      </section>
   }
}


export default Hero;