import { Grid, Hidden, Typography } from "@mui/material";
import One from '@mui/icons-material/LooksOne';
import Two from '@mui/icons-material/LooksTwo';
import Three from '@mui/icons-material/Looks3';
import GetStartedButton from "./GetStartedButton";
import qrCodeGif from '../media/img/qr-code.gif'

function StoryItem(props) {

   let left, right;
   const Icon = props.icon;

   const icon = <span 
      style={{ 
         display: 'inline-block',
         margin: 10,
         color: 'grey',
      }}
   >
      <Icon style={{ fontSize: 50 }} />
   </span>

   const style = {
      fontSize: 24,
      display: 'flex',
      margin: '20px 0',
      width: '90%',
      alignItems: 'center',
   }

   let justifyContent;

   if (props.right) {
      right = icon;
      justifyContent = 'right';
   } else {
      left = icon;
      justifyContent = 'left'
   }

   style.justifyContent = justifyContent;

   return <div style={{ display: 'flex', justifyContent  }}>
      <div style={style}>
         {left}
         {props.text}
         {right}
      </div>
   </div>
}

const storyItems = [
   { icon: One, text: 'Imagine seeing someone in the street, having an asthmatic episode' },
   { icon: Two, text: 'All you have to do is probably look for an inhaler in their backpack' },
   { icon: Three, text: 'But you can\'t, because you don\'t know that they\'re asthmatic' },
]

function AboutUs(props) {

   return <section 
         id="about" 
         style={{ 
            background: '#fafafa',
            // background: 'var(--primary)',
            // background: 'grey',
            // color: 'white',
            color: 'var(--accent-neg-2)'
         }}
      >
      <div>
         
         
         <Grid container>

            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item xs={12} md={6}>
                  <div className="fill-container vh-align">
                     <img src={qrCodeGif} alt="" />
                  </div>
               </Grid>
            </Hidden>

            <Grid item xs={12} md={6}>

               <div style={{ padding: 30 }}>
                  <Typography 
                     variant="h3"
                     style={{
                        fontWeight: 'bold'
                     }}
                  >
                     About KureTeq
                  </Typography>

                  {
                     storyItems.map((item, index) => {
                        return <StoryItem
                           icon={item.icon}
                           text={item.text}
                           right={index % 2 !== 0}
                        />
                     })
                  }

                  <Typography 
                     variant="h4"
                     style={{
                        fontWeight: 'bold'
                     }}
                  >
                     That's where we come in
                  </Typography>

                  <p style={{ fontSize: 20 }}>
                     If you get in a medical emergency, and you are unconcious, or you can't communicate for some other reason, our app allows emergency responders to scan a QR code on a band (that you buy from us) to get your medical information, just enough to know how to help you.
                  </p>

                  <GetStartedButton />
               </div>
            </Grid>

         </Grid>
      </div>
   </section>
}


export default AboutUs;