
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_DSN,
   integrations: [new BrowserTracing()],
   tracesSampleRate: 1.0,
});

function error(err) {
   console.error(err)
   Sentry.captureException(err);
}

function log(msg) {
   console.log(msg)
   Sentry.captureMessage(msg);
}

const logger = {
   error,
   log,
};


export default logger;