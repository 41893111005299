import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import Component from "@xavisoft/react-component";
import capitalize from "capitalize";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";
import { delay } from "../utils";



class MedicalConditionEditor extends Component {

   close = () => {
      this.props.close();
   }

   submit = async () => {
      // extract
      const txtDescription = document.getElementById('txt-description');
      const description = txtDescription.value;

      // validate
      if (!description) {
         errorToast('Describe the patient\'s medical condition');
         return txtDescription.focus();
      }

      // send data
      try {

         showLoading();

         if (this.props.mode === 'add') {

            let data = { 
               description, 
               patient: this.props.patientId
            };

            const res = await request.post('/api/doctor/medical-conditions', data);

            data = { ...data, ...res.data };
            this.props.close(data);

         } else {

            const data = { set: { description }}
            await request.patch(`/api/doctor/medical-conditions/${this.props.data.id}`, data);

            this.props.close({ description });
            
         }

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   async componentDidUpdate(prevProps) {

      if (this.props.mode === 'edit' && prevProps.mode !== 'edit') {
         await delay(500);
         document.getElementById('txt-description').value = this.props.data.description;
      }
      
   }

   render() {

      let shrink;

      if (this.props.mode === 'edit')
         shrink = true;

      return <Dialog open={this.props.open}>

         <DialogTitle>
            {capitalize(`${this.props.mode} medical condition`)}
         </DialogTitle>

         <DialogContent>
            <div style={{ width: '100%', minWidth: 400, padding: '20px 0' }}>
               <TextField
                  id="txt-description"
                  fullWidth
                  label="Description"
                  variant="standard"
                  size="small"
                  multiline
                  InputLabelProps={{
                     shrink,
                  }}
               />
            </div>
         </DialogContent>

         <DialogActions>
            <Button variant="contained" onClick={this.submit}>
               submit
            </Button>
            <Button onClick={this.close}>
               cancel
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default MedicalConditionEditor;