
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { Fragment } from "react";
import { connect } from "react-redux";
import { CONTACT_TYPES } from "../constants";
import ResponsiveTable from "./ResponsiveTable";

let typeToTypeObjectMap;
function mapContactTypeToIcon(type) {

   if (!typeToTypeObjectMap) {
      typeToTypeObjectMap = {};

      CONTACT_TYPES.forEach(ctype => {
         typeToTypeObjectMap[ctype.value] = ctype;
      });
   }

   const contactType = typeToTypeObjectMap[type];
   if (!contactType)
      return Fragment;

   return typeToTypeObjectMap[type].icon;

}

function formatDataForResponsiveTable(props) {

   

   const isScreenSizeSmall = [ 'xs', 'sm' ].includes(props.device.size);
   const typeStyle = {};
   let typeColumnLabel;

   if (isScreenSizeSmall) {
      typeColumnLabel = '';
   } else {
      typeStyle.textAlign = 'center';
      typeColumnLabel = <div className="center-align">TYPE</div>
   }

   const columns =  [
      typeColumnLabel, 'DATA', 'DESCRIPTION', ''
   ];

   const rows = props.contacts.map(contact => {


      const { id, type, data, description } = contact;
      const Icon = mapContactTypeToIcon(type);
      const typeJSX = <div style={typeStyle}>
         <Icon />
      </div>

      const action = <>
         <IconButton data-id={id} onClick={props.edit}>
            <Edit />
         </IconButton>
         <IconButton data-id={id} onClick={props.delete} color="error">
            <Delete />
         </IconButton>
      </>

      return {
         typeJSX,
         data,
         description,
         action
      }
   });

   return { columns, rows }
}



function ContactInfoManager(props) {


   const { rows, columns } = formatDataForResponsiveTable(props);
   
   return <div
      style={{
         height: '100%',
      }}
   >
      <div>
         <ResponsiveTable rows={rows} columns={columns} />
      </div>
   </div>
}

function mapStateToProps(state) {
   return { device: state.device }
}

export default connect(mapStateToProps)(ContactInfoManager);