import {Button, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Component from "@xavisoft/react-component";
import BackIcon from '@mui/icons-material/ArrowBackIosNew';
import capitalize from "capitalize";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";



export default class PharmacistDashboardPrescription extends Component {


   markAsDispensed = async () => {

      try {

         showLoading();

         await request.put(`/api/pharmacist/prescriptions/${this.props.data.id}/dispensal`);
         this.props.update({ dispensed: true });

      } catch (err) {
         console.log(err);
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {

      
      if (!!this.props.data) {

         const { patient, dispensed, items } = this.props.data;
         const { name, surname } = patient;
         const fullName = capitalize.words(`${name} ${surname}`);
         
         const actionJSX = dispensed ? undefined : <Button 
            variant="contained" 
            onClick={this.markAsDispensed} 
            fullWidth
         >
            MARK AS DISPENSED
         </Button>
         

         return <div
            style={{
               position: 'fixed',
               top: 0,
               bottom: 0,
               left: 0,
               right: 0,
               zIndex: 1,
               background: 'white',
            }}
            className="vh-align"
         >
            <div 
               style={{
                  maxWidth: 400,
                  width: '100%',
                  padding: 10,
               }}
            >
               <div>
                  <Button startIcon={<BackIcon />} onClick={this.props.close}>BACK</Button>

                  <Table>
                     <TableBody>
                        <TableRow>
                           <TableCell><b>MEDICATION FOR</b></TableCell>
                           <TableCell>{fullName}</TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell><b>ITEMS</b></TableCell>
                           <TableCell>{items}</TableCell>
                        </TableRow>
                        <TableRow>
                           <TableCell><b>DISPENSED</b></TableCell>
                           <TableCell>{dispensed ? 'YES': 'NO' }</TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>

                  {actionJSX}
               </div>
            </div>
         </div>
      }
   }
}

