
import './App.css';
import './animation.css'
import '@fontsource/public-sans';
import './linkHandler';

import { createTheme, ThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';
import palette from './palette';
import { init as initDeviceInfo } from './device';
import AppWrapper, { Route } from '@xavisoft/app-wrapper';
import Home from './pages/Home';
import Login from './pages/Login';
import Navbar from './components/Navbar';
import Signup from './pages/Signup';
import AdminDashboard from './pages/AdminDashboard';
import UpdatePassword from './components/UpdatePassword';
import DoctorDashboard from './pages/DoctorDashbboard';
import Test from './pages/Test';
import PageNotFound from './pages/PageNotFound';
import PatientDashboard from './pages/PatientDashboard';
import PharmacistDashboard from './pages/PharmacistDashboard';
import EmergencyResponderDashboard from './pages/EmergencyResponderDashboard';
import VerifyPatientAccount from './pages/VerifyPatientAccount';
import ErrorBoundary from './components/ErrorBoundary';
import Footer from './components/Footer';

const theme = createTheme({
  palette: {
    primary: {
      main: palette['--primary'],
      contrastText: 'white',
    },
    secondary: {
      main: palette['--accent-1'],
      contrastText: 'white',
    }
  }
});

function setDimensions() {
	
	const width = window.innerWidth + 'px';
	const height = window.innerHeight + 'px';

	document.documentElement.style.setProperty('--window-width', width);
	document.documentElement.style.setProperty('--window-height', height);

}

window.addEventListener('resize', setDimensions);
setDimensions();


initDeviceInfo();


const appWrapperProps = {};

if (window.cordova)
  appWrapperProps.router = 'hash'


function App() {
  return <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppWrapper {...appWrapperProps}>
          <Navbar />

          <Route path="/" component={Home} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={Signup} />
          <Route path="/admin-dashboard" component={AdminDashboard} />
          <Route path="/doctor-dashboard" component={DoctorDashboard} />
          <Route path="/patient-dashboard" component={PatientDashboard} />
          <Route path="/pharmacist-dashboard" component={PharmacistDashboard} />
          <Route path="/er-dashboard" component={EmergencyResponderDashboard} />
          <Route path="/er-dashboard/patients/:patientId" component={EmergencyResponderDashboard} />
          <Route path="/verification/:code" component={VerifyPatientAccount} />
          <Route path="/test" component={Test} />

          <Route path="*" component={PageNotFound} />

          <Footer />

          <UpdatePassword />

        </AppWrapper>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
}

export default App;
