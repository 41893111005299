import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import swal from "sweetalert";
import { hideLoading, showLoading } from "../loading";
import request from "../request";
import { errorToast } from "../toast";


class EnlistPatient extends Component {

   submit = async () => {

      // extract
      const txtNationalID = document.getElementById('txt-national-id');
      const national_id = txtNationalID.value;

      // validated
      if (!national_id) {
         errorToast('Provide the patient\'s national ID number');
         return txtNationalID.focus();
      }

      // submit
      try {

         showLoading();

         const res = await request.post(`/api/doctor/patients/${national_id}/enlistment`);
         swal('Patient enlisted successfully. Waiting for the patient\'s approval to be able to access patient information');

         this.props.close({
            national_id,
            ...res.data
         });

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }
   }

   render() {
      return <Dialog open={this.props.open}>
         <DialogTitle>ENLIST PATIENT</DialogTitle>

         <DialogContent>
            <TextField
               id="txt-national-id"
               label="Patient's national ID number"
               size="small"
               variant="standard"
               fullWidth
            />
         </DialogContent>

         <DialogActions>
            <Button onClick={this.props.close}>
               CANCEL
            </Button>
            <Button variant="contained" onClick={this.submit}>
               ENLIST
            </Button>
         </DialogActions>
      </Dialog>
   }
}

export default EnlistPatient;