import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import Component from "@xavisoft/react-component";
import { delay } from '../utils';
import { errorToast } from'../toast';
import { showLoading, hideLoading } from'../loading';
import swal from "sweetalert";
import request from '../request';
import { CONTACT_TYPES } from "../constants";


let typeToTypeObjectmap;
function mapContactTypeToInputType(type) {

   if (!typeToTypeObjectmap) {
      typeToTypeObjectmap = {};

      CONTACT_TYPES.forEach(ctype => {
         typeToTypeObjectmap[ctype.value] = ctype;
      });
   }

   const contactType = typeToTypeObjectmap[type];
   if (!contactType)
      return '';

   return typeToTypeObjectmap[type].inputType;

}


export default class ContactEditor extends Component {

   state = {
      values: {
         type: ''
      }
   }

   submit = async () => {
      // extract
      const txtType = document.getElementById('txt-type');
      const txtData = document.getElementById('txt-data');
      const txtDescription = document.getElementById('txt-description');

      const type = this.state.values.type;
      const data_ = txtData.value;
      const description = txtDescription.value;


      // check
      if (!type) {
         errorToast('Select contact type');
         return txtType.focus();
      }

      if (!data_) {
         errorToast('Add contact info');
         return txtData.focus();
      }

      if (!description) {
         errorToast('Describe your contact info');
         return txtDescription.focus();
      }

      // submit
      try {

         showLoading();
         const data = { description, data: data_, type };

         if (this.props.mode === 'add') {

            const res = await request.post('/api/patient/contact-info', data);
            
            this.props.close({
               ...data,
               ...res.data
            });
         } else {

            const updates = {};

            Object.keys(data).forEach(key => {
               if (data[key] !== this.props.data[key])
                  updates[key] = data[key];
            });
            
      
            if (Object.keys(updates).length === 0) {
               return errorToast('No changes made');
            }
            
            const id = this.props.data.id;
            const res  = await request.patch(`/api/patient/contact-info/${id}`, { set: updates });
            console.log(res);

            this.props.close({
               ...this.props.data,
               ...data
            });
         }

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   close = async () => {
      this.props.close();
   }

   async componentDidUpdate(prevProps) {
      if (this.props.mode === 'edit' && prevProps.mode !== 'edit') {

         const { type, data, description } = this.props.data;

         await this.updateState({
            values: {
               type,
            }
         });

         await delay(500);

         document.getElementById('txt-data').value = data;
         document.getElementById('txt-description').value = description;

      }
   }

   render() {

      const shrink = this.props.mode === 'edit' ? true : undefined;
      return <Dialog open={!!this.props.mode}>

         <DialogTitle>
            {capitalize(String(this.props.mode))} contact
         </DialogTitle>

         <DialogContent>
            <div className="form" style={{ width_: 300 }}>

               <div
                  style={{
                     display: 'grid',
                     gridTemplateColumns: '1fr 2fr',
                     columnGap: 40
                  }}
               >
                  <TextField
                     id="txt-type"
                     label="Type"
                     select
                     size="small"
                     variant="standard"
                     fullWidth
                     value={this.state.values.type}
                     onChange={this.onChangeHandlerGenerator('type')}
                     InputLabelProps={{
                        shrink,
                     }}
                  >
                     {
                        CONTACT_TYPES.map(type => {
                           return <MenuItem value={type.value}>{type.caption}</MenuItem>
                        })
                     }
                  </TextField>

                  <TextField
                     id="txt-data"
                     label="Info"
                     size="small"
                     variant="standard"
                     fullWidth
                     InputLabelProps={{
                        shrink,
                     }}
                     type={mapContactTypeToInputType(this.state.values.type)}
                  />
               </div>


               <TextField
                  id="txt-description"
                  label="Description"
                  size="small"
                  variant="standard"
                  fullWidth
                  multiline
                  InputLabelProps={{
                     shrink,
                  }}
                  helperText="e.g next of kin mobile phone number"
               />
            </div>
         </DialogContent>

         <DialogActions>
            <Button variant="contained" onClick={this.submit}>
               SAVE
            </Button>

            <Button onClick={this.close}>
               CANCEL
            </Button>
         </DialogActions>
      </Dialog>
   }
}
