
window.handleOpenURL = (link) => {

   const url = new window.URL(link);
   const pathname = url.pathname.replaceAll('//', '/');
   const currentPathname = window.location.pathname;
   const route = `${pathname}${url.search}`;

   window.App.redirect(route);

   if (currentPathname === pathname) {
      window.location.reload(); // making sure that the page remounts
   }

}