
import { Button, Grid, Hidden, MenuItem, TextField } from '@mui/material';
import Page from './Page';
import welcomeJPG from '../media/img/welcome.jpeg';

import { css } from '@emotion/css';
import { errorToast } from '../toast';
import swal from 'sweetalert';
import request from '../request';
import { hideLoading, showLoading } from '../loading';
import { HashLink } from 'react-router-hash-link';
import { MetaTags } from 'react-meta-tags';


const divLoginClass = css({

   '& > *': {
      margin: '10px auto !important',
   },

   margin: 20,

   '& a': {
      color: '#00e',
      textUnderlineOffset: 3,
   }
});

const sexes = [
   { value: 'male', label: 'Male' },
   { value: 'female', label: 'Female' },
   { value: 'unspecified', label: 'Rather not say' },
];

class Signup extends Page {

   state = {
      values: {
         sex: ''
      }
   }

   signup = async () => {

      // extraction
      const txtName = document.getElementById('txt-name');
      const txtSurname = document.getElementById('txt-surname');
      const txtNationalId = document.getElementById('txt-national-id');
      const txtEmail = document.getElementById('txt-email');
      const txtPassword = document.getElementById('txt-password');
      const txtConfirm = document.getElementById('txt-confirm');
      const txtSex = document.getElementById('txt-sex');
      const txtDOB = document.getElementById('txt-dob');

      const name = txtName.value;
      const surname = txtSurname.value;
      const national_id = txtNationalId.value;
      const email = txtEmail.value;
      const password = txtPassword.value;
      const confirm = txtConfirm.value;
      const sex = this.state.values.sex;
      const dob = txtDOB.value;

      // presense check
      if (!name) {
         errorToast('Please provide your first name(s)');
         return txtName.focus();
      }

      if (!surname) {
         errorToast('Please provide your last name');
         return txtSurname.focus();
      }

      if (!national_id) {
         errorToast('Please provide your national ID number');
         return txtNationalId.focus();
      }

      if (!/[0-9]{2}-[0-9]{4,7}[A-Za-z]{1}[0-9]{2}/gi.test(national_id)) {
         errorToast('Invalid national ID number');
         return txtNationalId.focus();
      }

      if (!email) {
         errorToast('Please provide your email address');
         return txtEmail.focus();
      }

      if (!password) {
         errorToast('Please choose a password for your account');
         return txtPassword.focus();
      }

      if (!confirm) {
         errorToast('Please reenter your password');
         return txtConfirm.focus();
      }

      if (confirm !== password) {
         errorToast('Your passwords do not match');
         txtPassword.value = '';
         txtConfirm.value = '';
         return txtPassword.focus();
      }

      if (!sex) {
         errorToast('Please choose your sexual orientation');
         return txtSex.focus();
      }

      if (!dob) {
         errorToast('Please provide your date of birth');
         return txtDOB.focus();
      }


      // submit
      const data = {
         name,
         surname,
         national_id,
         email,
         password,
         sex,
         dob,
      }

      console.log(data);

      try {

         showLoading();
         await request.post('/api/patient/patients', data);

         // clear form
         await this.updateState({ values: { sex: '' }})
         const divLogin = document.querySelector(`.${divLoginClass}`);
         Array.from(divLogin.querySelector('input')).forEach(input => input.value = '');

         swal('You account has been created, check your email to verify.');

      } catch (err) {
         swal(String(err));
      } finally {
         hideLoading();
      }

   }

   _render() {
      return <>
         <MetaTags>
            <title>Sign up | KureTeq</title>
         </MetaTags>

         <Grid container>
            <Hidden only={[ 'xs', 'sm' ]}>
               <Grid item xs={6}>
                  <div
                     style={{
                        height: 'calc(var(--window-height) - var(--navbar-height, 0))',
                        background: `url(${welcomeJPG})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%'
                     }}
                  />
               </Grid>
            </Hidden>

            <Grid item xs={12} md={6}>
               <div className='fill-container vh-align'>
                  

                  <div 
                     style={{
                        width: '100%',
                        maxWidth: 400,
                     }}
                     className={divLoginClass}
                  >

                     <h4>SIGNUP</h4>

                     <TextField
                        id="txt-name"
                        variant="outlined"
                        size="small"
                        label="First name(s)"
                        fullWidth
                     />

                     <TextField
                        id="txt-surname"
                        variant="outlined"
                        size="small"
                        label="Last name"
                        fullWidth
                     />

                     <TextField
                        id="txt-national-id"
                        variant="outlined"
                        size="small"
                        label="National ID"
                        helperText="##-#####L##"
                        fullWidth
                     />

                     <TextField
                        id="txt-email"
                        variant="outlined"
                        size="small"
                        label="Email"
                        fullWidth
                        type="email"
                     />

                     <TextField
                        id="txt-password"
                        variant="outlined"
                        size="small"
                        label="Password"
                        type="password"
                        fullWidth
                     />

                     <TextField
                        id="txt-confirm"
                        variant="outlined"
                        size="small"
                        label="Confirm password"
                        type="password"
                        fullWidth
                     />

                     <div
                        style={{
                           display: 'grid',
                           gridTemplateColumns: '1fr 1fr',
                           columnGap: 30
                        }}
                     >
                        <div>
                           <TextField
                              variant="outlined"
                              id="txt-sex"
                              size="small"
                              label="Sex"
                              fullWidth
                              onChange={this.onChangeHandlerGenerator('sex')}
                              value={this.state.values.sex}
                              select
                           >
                              {
                                 sexes.map(sex => {
                                    return <MenuItem value={sex.value}>{sex.label}</MenuItem>
                                 })
                              }
                           </TextField>
                        </div>

                        <div>
                           <TextField
                              id="txt-dob"
                              variant="outlined"
                              size="small"
                              label="Date of birth"
                              fullWidth
                              type="date"
                              InputLabelProps={{
                                 shrink: true
                              }}
                           />
                        </div>
                     </div>

                     <Button 
                        variant="contained"
                        fullWidth
                        onClick={this.signup}
                        size="large"
                     >
                        SUBMIT
                     </Button>

                     <div className='center-align'>
                        Already have an account?
                        {" "}
                        <HashLink to="/login">Log in</HashLink>
                     </div>

                  </div>


               </div>
            </Grid>
         </Grid>
      </>
   }
}

export default Signup;